import React, { useState } from 'react'
import { InstagramIcon, TwitterIcon, TikTokIcon, FacebookIcon, YoutubeIcon, LinktreeIcon } from '../components/icons'
import { motion } from 'framer-motion'
import transition from '../transition'
import { getUnitList } from '../constants/livedata'
import useLiveData from '../hooks/useLiveData'

const container = {
    hidden: {},
    show: {
        rotate: 0,
        transition: {
            staggerChildren: 0.1,
            delayChildren: 0
        }
    }
};

const item = {
    hidden: { opacity: 0, top: 40 },
    show: { opacity: 1, top: 0 }
}


const Team = () => {

    //edit this with live data
    let providers = useLiveData(getUnitList);

    //providers.filter((provider) => !["Engineer", "A Room", "B Room"].some(e => provider.name.includes(e)))

    const [openBio, setOpenBio] = useState([])

    function Socials(e) {
        if (e.Link) {
            if (e.Title === 'Instagram') {
                return (
                    <li>
                        <a href={e.Link}>
                            <InstagramIcon className="w-6 h-6 md:w-8 md:h-8 fill-white hover:scale-110 transition-all duration-300 ease-in-out" />
                        </a>
                    </li>
                )
            }
            if (e.Title === 'Twitter') {
                return (
                    <li>
                        <a href={e.Link}>
                            <TwitterIcon className="w-6 h-6 md:w-8 md:h-8 fill-white hover:scale-110 transition-all duration-300 ease-in-out" />
                        </a>
                    </li>
                )
            }
            if (e.Title === 'Facebook') {
                return (
                    <li>
                        <a href={e.Link}>
                            <FacebookIcon className="w-6 h-6 md:w-8 md:h-8 fill-white hover:scale-110 transition-all duration-300 ease-in-out" />
                        </a>
                    </li>
                )
            }
            if (e.Title === 'Youtube') {
                return (
                    <li>
                        <a href={e.Link}>
                            <YoutubeIcon className="w-6 h-6 md:w-8 md:h-8 fill-white hover:scale-110 transition-all duration-300 ease-in-out" />
                        </a>
                    </li>
                )
            }
            if (e.Title === 'TikTok') {
                return (
                    <li>
                        <a href={e.Link}>
                            <TikTokIcon className="w-6 h-6 md:w-8 md:h-8 fill-white hover:scale-110 transition-all duration-300 ease-in-out" />
                        </a>
                    </li>
                )
            }
            if (e.Title === 'Linktree') {
                return (
                    <li>
                        <a href={e.Link}>
                            <LinktreeIcon className="w-6 h-6 md:w-8 md:h-8 fill-white hover:scale-110 transition-all duration-300 ease-in-out" />
                        </a>
                    </li>
                )
            }
        }
    }

    const jsonCard = ({ jsonDescription }) => {


        return (
            <>
            </>
        )
    }
    return (
        <>
            {providers &&
                <section className='w-full h-auto flex items-start justfy-center pt-24'>
                    <motion.ul
                        variants={container}
                        initial="hidden"
                        animate="show"
                        className='w-full h-auto flex flex-col lg:flex-row lg:px-4 flex-wrap items-center justify-around gap-3 lg:gap-1'>
                        {providers && providers.map((engineer, index) => {
                            try {
                                //validates if the description can be parsed
                                let jsonDescription = (JSON.parse(engineer.description))
                                return (
                                    <motion.li
                                        variants={item}
                                        id={engineer.id} className={`flex flex-col items-center justify-between lg:justify-start w-full bt-4 md:w-[60%] lg:w-[45%] h-auto   my-2 shadow shadow-black/20 rounded-tl-lg rounded-br-lg bg-white hover:shadow-black/50 hover:shadow-md lg:hover:scale-[1.08] ${(index % 2) === 0 ? 'lg:hover:translate-x-8 lg:hover:-rotate-2' : 'lg:hover:-translate-x-8 lg:hover:rotate-2'}  transition-all duration-300`}>
                                        <img className="flex-shrink-0 h-[350px] lg:h-[400px] w-[350px] lg:w-[400px] object-cover rounded-sm shadow-black shadow-sm " src={engineer.picture_path ? `https://phxdown.simplybook.me/${engineer.picture_path}` : '/default.png'} />
                                        <div className='flex flex-col w-full items-center justify-between h-full gap-4 lg:gap-2'>
                                            <div className='w-full flex-row items-center justify-around lg:justify-center flex md:flex-col bg-black mt-2 border-x-white border-x-2 rounded-sm'>
                                                <div className='flex flex-col lg:flex-row items-center justify-center py-2 px-2 lg:px-0 lg:w-full'>
                                                    <h2 className='font-bold text-2xl lg:text-4xl text-center w-full text-white'>{engineer.name}</h2>
                                                    <div className='flex flex-col w-full items-center justify-center gap-1'>
                                                        {jsonDescription.Handle &&
                                                            <p className='font-light text-md text-white'>{jsonDescription.Handle}</p>}

                                                        <ul className='w-[50%] flex flex-row items-center justify-center gap-3'>
                                                            {jsonDescription.Socials &&
                                                                jsonDescription.Socials.map((e) => <Socials {...e} />
                                                                )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-full flex flex-col items-center justify-center pb-2  text-black/80'>
                                                <p className='lg:text-2xl font-regular w-[95%]  mx-auto text-center border-b border-black my-2'>Recent Credits</p>
                                                <ul className='w-full flex flex-row items-center justify-between flex-wrap px-4 lg:px-4'>
                                                    {jsonDescription.Credits && jsonDescription.Credits.map((credit, index) => {
                                                        return (
                                                            <li className='w-full '>
                                                                <a href={credit.link} className='w-full flex items-center justify-center'>
                                                                    {/* Tangent string to replace the &amp; that happens when you include the & symbol in a JSON object */}
                                                                    <h3 className={`text-sm lg:text-lg ${(index % 2) == 0 ? 'text-left' : 'text-right'} font-light hover:underline`}>{credit.Title.toString().replace(/amp;+/, "")}</h3>
                                                                </a>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>

                                            </div>

                                        </div>
                                        <div className='group w-full flex flex-col items-center justify-start bg-white  text-black/80'>
                                            {openBio.includes(index) ?
                                                <button className='lg:text-2xl font-regular w-auto px-4 border-2 rounded-md mx-auto text-center my-2 group select-none' onClick={() => setOpenBio([null])}>CLOSE</button>
                                                :
                                                <button className='lg:text-2xl font-regular w-auto px-4 border-2 rounded-md mx-auto text-center my-2 group select-none' onClick={() => setOpenBio([index])}>BIO</button>
                                            }

                                            <div className={`${openBio.includes(index) ? 'h-auto' : 'h-0'} overflow-hidden `}>
                                                <p className='px-2 pb-2 text-black/80'> {jsonDescription.Bio.toString().replace(/amp;+/, "")}</p>
                                            </div>
                                        </div>
                                    </motion.li>
                                )
                            } catch (e) {
                                return (
                                    <motion.li
                                        variants={item}
                                        id={engineer.id} className={`flex flex-col items-center justify-between lg:justify-start w-full bt-4 md:w-[60%] lg:w-[45%] h-auto lg:h-[600px]  my-2 shadow shadow-black/20 rounded-tl-lg rounded-br-lg bg-white hover:shadow-black/50 hover:shadow-md lg:hover:scale-[1.08] ${(index % 2) === 0 ? 'lg:hover:translate-x-8 lg:hover:-rotate-2' : 'lg:hover:-translate-x-8 lg:hover:rotate-2'}  transition-all duration-300`}>
                                        <img className="flex-shrink-0 h-[350px] lg:h-[400px] w-[350px] lg:w-[400px] object-cover rounded-sm shadow-black shadow-sm" src={engineer.picture_path ? `https://phxdown.simplybook.me/${engineer.picture_path}` : '/default.png'} />
                                        <div className='flex flex-col w-full items-center justify-between h-full gap-12 lg:gap-2'>
                                            <div className='w-full items-center justify-around lg:justify-center flex flex-col bg-black mt-2 border-x-white border-x-2 rounded-sm'>
                                                <div className='flex flex-col lg:flex-row items-center justify-center py-2 px-2 lg:px-0 w-full'>
                                                    <h2 className='font-bold text-2xl lg:text-4xl w-[50%] lg:w-full text-center text-white'>{engineer.name}</h2>
                                                </div>
                                                <h2 className='text-lg lg:text-2xl w-full md:w-[50%] lg:w-full text-center text-white mb-2'>{engineer.email}</h2>

                                            </div>
                                        </div>
                                    </motion.li>
                                )
                            }
                        })}

                    </motion.ul>

                </section>
            }
        </>

    )
}

export default transition(Team)