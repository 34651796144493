import { motion } from "framer-motion";
import React from "react";

export const CarouselItems = ({ index, renderPage, x, onDragEnd, itemsPerView }) => {
  const child = React.useMemo(() => renderPage({ index }), [index, renderPage]);
  const itemWidth = 100 / itemsPerView;
  const pageStyle = {
    position: "absolute",
    width: `${itemWidth}%`,
  };

  return (
    <motion.div
      style={{
        ...pageStyle,
        x,
        left: `${index * itemWidth}%`,
        right: `${index * itemWidth}%`
      }}
      className="h-80"
      draggable
      drag="x"
      dragElastic={1}
      onDragEnd={onDragEnd}
    >
      {child}
    </motion.div>
  );
};

// Page.displayName = "page";