import axiosInstance, {SetHeaders} from "../interceptors/axios"
import { useEffect, useState } from "react";

export default function useLiveData({urlObject, method, params}, dependency = []) {
    const [data, setData] = useState(null);
    useEffect(() => {
      if (urlObject.url) {
        let ignore = false;

        axiosInstance.post(urlObject.url, {
            "jsonrpc": "2.0",
            "method": method,
            "params": params,
            "id": Date.now()
        }, {
            params: urlObject.params,
            headers: SetHeaders(urlObject)
        })
        .then(response => response)
        .then(json => {
          if (!ignore) {
            setData(json.data.message.result);
          }
        });          
        return () => {
          ignore = true;
        };
      }
    }, [...dependency, urlObject.url]);
    return data;
  }