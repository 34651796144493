import { BrowserRouter } from "react-router-dom";
import RouteStructure from './Routes';
import { getLocationsList, getUnitList, getEventList, getCategoriesList } from "./constants/livedata";
import useLiveData from "./hooks/useLiveData";
function App() {

    const LiveData = { locations: useLiveData(getLocationsList), providers: useLiveData(getUnitList), services: useLiveData(getEventList), categories: useLiveData(getCategoriesList) }
    return (
        <BrowserRouter className='App'>
            <RouteStructure locations={LiveData.locations} providers={LiveData.providers} services={LiveData.services} categories={LiveData.categories} />
        </BrowserRouter>
    );
}

export default App;

