import { motion } from "framer-motion";
import { PhoenixIcon } from './components/icons'

import React from 'react'

const transition = (Component) => {
  return () => (
    <>
    <Component />
    {/* Slide-In */}
        <motion.div 
        initial={false}
        animate={{scaleY:0}}
        exit={{ scaleY: 1}}
        transition={{duration: 0.5, ease:[0.22, 1, 0.36, 1]}}
        className="fixed top-0 left-0 z-[999] w-full h-screen bg-black origin-bottom flex items-center justify-center"
        >
                    <PhoenixIcon className="w-[80%] h-[80%] fill-phoenixOrange"/>

                  </motion.div>

  {/* Slide-Out */}
  <motion.div 
        initial={{opacity: 1}}
        animate={{opacity:0, scaleY: 0}}
        exit={{ opacity: 0}}
        transition={{duration: 1, ease:[0.22, 1, 0.36, 1]}}
        className="fixed top-0 left-0 z-[999]  w-full h-screen bg-black origin-top flex items-center justify-center"
        >
          <PhoenixIcon className="w-[80%] h-[80%] fill-phoenixOrange"/>
          </motion.div>

    </>
  )
}

export default transition