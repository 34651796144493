import React from 'react'

export const BookingMenuWidgetHeader = ({setCurrentMenu, currentMenu, setBookingMenuOpen, selectedDuration, selectedProvider, selectedLocation, closeMenu}) => {

  const handleMenuClose = () => {
    setBookingMenuOpen(false)
  }

  return (
    <div className='w-full py-4 px-2 lg:px-4 flex items-center justify-between border-b border-b-white absolute top-0 z-[60] bg-black'>
    {currentMenu === 'Info' ? 
    <h2 className='text-xl lg:text-4xl font-semibold'>Booking</h2>
    : 
    
    <div className='w-auto lg:w-[60%] flex flex-row items-end justify-between lg:justify-center  gap-2' >
    <p onClick={() => setCurrentMenu('Info')} className='bg-white border-white border rounded-md px-4 py-1 lg:py-3 lg:text-lg lg:font-bold lg:px-20 text-black font-semibold text-sm cursor-pointer'>EDIT</p>
    <div className='flex flex-row items-center justify-center w-auto gap-2  lg:gap-4 lg:px-2 lg:pr-4'>
        <h2 className='w-auto text-left text-md lg:text-lg ml-2 select-none'>{selectedProvider.name}</h2> 
        <h3 className='text-sm lg:text-xl select-none '>{selectedLocation.name}</h3>
        <h3 className='text-sm lg:text-xl select-none '>{(selectedDuration/60)}-Hour Session</h3> 
    </div>

   </div>
    }
    <button onClick={() => closeMenu()} className='lg:text-2xl lg:mr-8'>
    CLOSE</button> 
  </div>
  )
}
