import React, {useState, useEffect, useRef} from 'react'
import { useLocation } from 'react-router-dom'
import { BookNowIcon } from './BookNowIcon'
import { motion, AnimatePresence } from 'framer-motion'
import SimplyBookingWidget from './SimplyBookingWidget'
import { BookingMenuWidgetHeader } from './BookingMenuWidgetHeader'
import { BookingInfoWidgetMenu } from './BookingInfoWidgetMenu'
import { useIsMedium } from '../hooks/useMediaQuery'

export const BookingMenuWidget = ({currentUser, setCurrentUser, providers, locations, services, categories, bookingMenuOpen, setBookingMenuOpen, currentPage}) => {
  
// Record if User is viewing the 'Home', 'Info', 'Login', or 'Confirm' Page, and display this page in the Booking Menu
  const [currentMenu, setCurrentMenu] = useState('Info')

//Record selections made by User, to pass into Iframe
  const [selectedProvider, setSelectedProvider] = useState()
  const [selectedLocation, setSelectedLocation] = useState()
  const [selectedCategory, setSelectedCategory] = useState()
  const [selectedService, setSelectedService] = useState()
  const [selectedDuration, setSelectedDuration] = useState()

  function closeMenu(){
    setBookingMenuOpen(false)
    setCurrentMenu('Info')
    setSelectedProvider()
    setSelectedLocation()
    setSelectedDuration()
  }

  useEffect(() => {
    if (selectedProvider && categories){
      let categoryArray = Object.values(categories)
      categoryArray.forEach((category) => {
        if (category.units[0] === Number(selectedProvider.id)) {
          setSelectedCategory(category)
        }
      })
    }
  }, [selectedProvider])

//Control position of Booking Icon based on page being viewed (to avoid conflict with UI)
  let page = useLocation()
  const [iconPosition, setIconPosition] = useState('bottom')
  useEffect(() => {
    if ( page.pathname != '/' ){
      setIconPosition('top')
    } else {
      setIconPosition('bottom')
    }
  }, [page])

//Calculate all possible durations from available services
const [durations, setDurations] = useState()
useEffect(() => { if (services){
  setDurations([...new Set(services.map(service => service.duration)) ].sort())
}
}, [services])   

const isMedium = useIsMedium()

const ref = useRef()

useEffect(() => {
  const checkIfClickedOutside = e => {
    // If the menu is open and the clicked target is not within the menu,
    // then close the menu
    if (bookingMenuOpen && ref.current && !ref.current.contains(e.target)) {
      closeMenu()
    }
  }

  document.addEventListener("mousedown", checkIfClickedOutside)

  // Increase the logo size to overflow the navbar, ex: scale large and translate -y

  return () => {
    // Cleanup the event listener
    document.removeEventListener("mousedown", checkIfClickedOutside)
  }
}, [bookingMenuOpen])

  return (
    <>
    <BookNowIcon currentPage={currentPage} setMenuOpen={setBookingMenuOpen} isMenuOpen={bookingMenuOpen}/>
    {isMedium ? <>{providers && durations && locations && categories ?
    <AnimatePresence >
      {bookingMenuOpen && 
      <motion.div 
      initial={{opacity: 0}}
      animate={{x:0, opacity: 1}}
      exit={{x:-1000, opacity: 0}}
      transition={{type: 'tween', duration: 0.2}}
      className='fixed z-[50] w-full md:h-screen flex items-center justify-center bg-black/80  lg:px-24 xl:px-80'> 
      <div ref={ref} className='flex flex-col items-center justify-between w-full h-full bg-black text-white shadow-md shadow-black'> 
        <BookingMenuWidgetHeader {...{setCurrentMenu, currentMenu, setBookingMenuOpen, selectedProvider, selectedLocation, selectedDuration, closeMenu}}  />
        <div className='w-full h-full border-t border-eggshell'>
          <AnimatePresence> 
              {currentMenu === 'Info' &&
                <motion.div
                initial={false}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{type:'tween', duration: .2, delay: 0.3}} 
                className='w-full h-full'> 
                  <BookingInfoWidgetMenu {...{setSelectedProvider, setSelectedLocation, setCurrentMenu, selectedProvider, selectedLocation, selectedCategory, setSelectedCategory, setSelectedService, selectedDuration, setSelectedDuration}} providers={providers} locations={locations} services={services} categories={categories} durations={durations}/>
                </motion.div> 
              } 
            </AnimatePresence>
            {currentMenu === 'Widget' && 
              <motion.div
              initial={{y: 200}}
              animate={{y: 0}}
              exit={{y: 100}}
              transition={{ease: 'easeOut', duration: 0.2, delay: .2}}
              className='w-full h-full'>
                  <SimplyBookingWidget {...{selectedProvider, selectedService, selectedCategory, selectedLocation}}/>
              </motion.div>
            }
        </div>
        </div>
      </motion.div>
      }
    </AnimatePresence >
   :     <AnimatePresence >
   {bookingMenuOpen && 
   <motion.div 
   initial={{y: 100, opacity: 0.7}}
   animate={{y:0, opacity: 1}}
   exit={{y:1000, opacity: 0}}
   transition={{type: 'tween', duration: 0.5}}
   className='fixed z-[50] w-full h-screen bg-black text-white flex flex-col items-center justify-between'> 
     <BookingMenuWidgetHeader {...{setCurrentMenu, currentMenu, setBookingMenuOpen}} />
     <div className='w-full h-full px-2 border-t border-eggshell flex items-center justify-center'>
       <div className='text-xl text-center w-full px-4 lg:px-15 flex flex-col items-center justify-center'>
          <span className='text-2xl'>Contact us at  </span>
          <span className='font-bold'>phoenixdownrecording@gmail.com  </span>
          <span className='text-2xl'>to Book a Session </span>
        </div>
     </div>
   </motion.div>
   }
 </AnimatePresence > }</> 
    
    
    
    
    : <>{providers && durations && locations && categories ?
    <AnimatePresence >
      {bookingMenuOpen && 
      <motion.div 
      initial={{y: 100, opacity: 0.7}}
      animate={{y:0, opacity: 1}}
      exit={{y:1000, opacity: 0}}
      transition={{type: 'tween', duration: 0.5}}
      className='fixed z-[50] w-full h-full bg-black text-white'> 
        <BookingMenuWidgetHeader {...{setCurrentMenu, currentMenu, setBookingMenuOpen, selectedProvider, selectedLocation, selectedDuration, closeMenu}} />
          <AnimatePresence> 
              {currentMenu === 'Info' &&
                <motion.div
                initial={false}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{type:'tween', duration: .2, delay: 0.3}} 
                className='w-full h-full'> 
                  <BookingInfoWidgetMenu {...{setSelectedProvider, setSelectedLocation, setCurrentMenu, selectedProvider, selectedLocation, selectedCategory, setSelectedCategory, setSelectedService, selectedDuration, setSelectedDuration}} providers={providers} locations={locations} services={services} categories={categories} durations={durations}/>
                </motion.div> 
              }
            </AnimatePresence>
            {currentMenu === 'Widget' && 
              <motion.div
              initial={{y: 200}}
              animate={{y: 0}}
              exit={{y: 100}}
              transition={{ease: 'easeOut', duration: 0.2, delay: .2}}
              className='w-full h-full'>
                  <SimplyBookingWidget {...{selectedProvider, selectedService, selectedCategory, selectedLocation}}/>
              </motion.div>
            }
      </motion.div>
      }
    </AnimatePresence >
   :     <AnimatePresence >
   {bookingMenuOpen && 
   <motion.div 
   initial={{y: 100, opacity: 0.7}}
   animate={{y:0, opacity: 1}}
   exit={{y:1000, opacity: 0}}
   transition={{type: 'tween', duration: 0.5}}
   className='fixed z-[50] w-full h-screen bg-black text-white flex flex-col items-center justify-between'> 
     <BookingMenuWidgetHeader {...{setCurrentMenu, currentMenu, setBookingMenuOpen}} />
     <div className='w-full h-full px-2 border-t border-eggshell flex items-center justify-center'>
       <div className='text-xl text-center w-full px-4 lg:px-15 flex flex-col items-center justify-center'>
          <span className='text-2xl'>Contact us at  </span>
          <span className='font-bold'>phoenixdownrecording@gmail.com  </span>
          <span className='text-2xl'>to Book a Session </span>
        </div>
     </div>
   </motion.div>
   }
 </AnimatePresence > }</> }
    
    </>
  )
}

