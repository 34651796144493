import * as React from "react";
import { animate, motion, useMotionValue } from "framer-motion";
import { useState, useEffect } from "react";
import { CarouselItems } from "./CarouselItems";


const transition = {
  type: "spring",
  bounce: 0
};

export const VirtualizedCarousel = ({ children, itemCount, itemsPerView = 3 }) => {
  const x = useMotionValue(0);
  const containerRef = React.useRef(null);
  const [index, setIndex] = React.useState(0);

  const fillerArray = Array.apply(null, Array(itemCount)).map((_, i) => i);
  const fillerArrayReverse = fillerArray.reverse().map((_, i) => i * -1);
  // create a range array starting at -itemCount and ending at itemCount to hide the items that get destroyed at the begining / end of the slider
  // fillerArray fills in the in between values e.g. 0, 1, 2, 3, 4…
  // fillerArrayReverse does the same but starting at negative n, so e.g. if itemCount is 11 = -11, -10, -9, -8…
  const range = [
    ...new Set([
      ...fillerArrayReverse.reverse(),
      ...fillerArray.reverse(),
      itemCount
    ])
  ];

  const calculateNewX = React.useCallback(
    () => -index * (containerRef.current?.clientWidth / itemsPerView || 0),
    [index, itemsPerView]
  );

  // How far user has to drag inside the container before triggering animation
  const animationTrigger = (width) => width / 4;

  const translate = (direction, offset = 1) => {
    offset = offset <= 0 ? 1 : offset;
    if (!hovered) {
      setTimeout(() => {
        if (direction === "left") {
          setIndex(index - 1 * offset);
        } else {
          setIndex(index + 1 * offset);
        }
      }, 1200);
    }
  };

  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    translate();
  });

  const handleEndDrag = (e, dragProps) => {
    const clientWidth = containerRef.current?.clientWidth / itemsPerView || 0;

    const { offset } = dragProps;
    const dragDistanceOffset = Math.floor(Math.abs(offset.x / clientWidth));

    if (offset.x > animationTrigger(clientWidth)) {
      translate("left", dragDistanceOffset);
    } else if (offset.x < -animationTrigger(clientWidth)) {
      translate("right", dragDistanceOffset);
    } else {
      animate(x, calculateNewX(), transition);
    }
  };

  React.useEffect(() => {
    const controls = animate(x, calculateNewX(), transition);
    return controls.stop;
  }, [index, calculateNewX, x]);

  return (
    <motion.div
      ref={containerRef}
      className="relative w-full h-80 mt-2"
      onHoverStart={() => setHovered(true)}
      onHoverEnd={() => setHovered(false)}
    >
      {range.map((rangeValue) => {
        return (
          <CarouselItems
            key={rangeValue + index}
            x={x}
            onDragEnd={handleEndDrag}
            index={rangeValue + index}
            renderPage={children}
            itemsPerView={itemsPerView}
          />
        );
      })}
      <div
        style={{
          display: "flex",
          position: "absolute",
          bottom: 0,
          width: "100%",
          justifyContent: "space-between"
        }}
      ></div>
    </motion.div>
  );
};

// VirtualizedPage.displayName = "VirtualizedPage";
