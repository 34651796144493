import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router";
const AppSettings = require('../appsettings.json');
 

const https = require("https");
const agent = new https.Agent({
    rejectUnauthorized: false
});
const instance = axios.create({
    httpAgent: agent
});

const FetchToken = async (type) => {
    let res = await axios.post("https://api.phoenixdownrecording.com/phnxdownapi/refresh/ignore?type="+type, {
        "jsonrpc": "2.0",
        "id": Date.now()
    }, {
        httpAgent: agent
    })

    let keyValue = res.data["result"];
    if (keyValue?.length == 2) {
        localStorage.setItem(keyValue[0], keyValue[1]);
        return keyValue; 
    }
    return null;
}

const DoTokensExist = () => {
    if (!localStorage.getItem("cmptkn") || !localStorage.getItem("usertkn")) return false;
} 

const SetHeaders = (config, tokenResult = [], sourceString) => {
    let headers = {};
    if (tokenResult[0]){
        //console.log(`from ${sourceString} - new token ${tokenResult?.length == 2 ? "set" : "not set"} in local storage`);
    }
    switch(config?.params?.api){
        case "user":
            headers = {...config.headers, "X-Company-Login": AppSettings.CompanyName, "X-User-Token": tokenResult[1] ?? localStorage.getItem("usertkn")}
            break;
        case "cmp":
            headers = {...config.headers, "X-Company-Login": AppSettings.CompanyName, "X-Token": tokenResult[1] ?? localStorage.getItem("cmptkn")}
            break;
    }
    return headers;
}
const AxiosInterceptor = ({ children }) => {
    const [isSet, setIsSet] = useState(false)
    //console.log("interceptor");
    const navigate = useNavigate();

    useEffect(() => {
        //console.log("useEffect");

        const reqInterceptor = async config => {
            //console.log("reqInterceptor");
            if (config.url != "test for refreshing here" && !DoTokensExist()) {
                let tokenResult = await FetchToken(config?.params?.api);
                config.headers = SetHeaders(config, tokenResult, "request")
            }
            return config;
        }

        let refresh = false;
        const resInterceptor = async res => {
            //console.log("resInterceptor");
            if (res.data?.["message"]?.["error"] && res.data?.["message"]?.["error"]?.["message"] == "Access denied" && !refresh) {
                refresh = true;
                let tokenResult = await FetchToken(res.config?.params?.api);
                res.config.headers = SetHeaders(res.config, tokenResult, "response")
                return instance.request(res.config);
            }
            refresh = false;
            return res;
        }

        const errInterceptor = error => {
            //console.log("errInterceptor");
            if (error.response.status === 401) {
                navigate('/');
            }

            return Promise.reject(error);
        }


        const reqinterceptor = instance.interceptors.request.use(reqInterceptor, errInterceptor);
        const resinterceptor = instance.interceptors.response.use(resInterceptor, errInterceptor);


        setIsSet(true)
        return () => {
            instance.interceptors.request.eject(reqinterceptor)
            instance.interceptors.response.eject(resinterceptor)
        };

    }, [navigate])

    return isSet && children;
}


export default instance;
export { AxiosInterceptor, SetHeaders}