import React, { useEffect, useRef, useState } from 'react';
import { SimplybookWidget } from './widget';
function SimplySignInWidget({ selectedProvider, selectedService, selectedLocation, selectedCategory }) {
    const widgetContainerRef = useRef(null);
    useEffect(() => {
        let predefined = {
        }
        if (selectedProvider) {
            predefined.provider = selectedProvider.id
        }
        if (selectedService) {
            predefined.service = selectedService.id
        }
        if (selectedLocation) {
            predefined.location = selectedLocation.id
        }
        if (selectedCategory) {
            predefined.category = selectedCategory.id
        }



        new SimplybookWidget(
            {
                "widget_type": "iframe",
                "url": "https:\/\/phxdown.simplybook.me",
                "navigate": "client/sign-in",
                "theme": "concise",
                "theme_settings": {
                    "timeline_hide_unavailable": "1",
                    "hide_past_days": "0",
                    "timeline_show_end_time": "1",
                    "timeline_modern_display": "as_slots",
                    "light_font_color": "#ffffff",
                    "sb_secondary_base": "#0b0d0e",
                    "sb_base_color": "#5281bc",
                    "display_item_mode": "list",
                    "booking_nav_bg_color": "#212020",
                    "sb_review_image": "",
                    "dark_font_color": "#0b0d0e",
                    "btn_color_1": "#385e8e",
                    "sb_company_label_color": "#5281bc",
                    "hide_img_mode": "0",
                    "show_sidebar": "1",
                    "sb_busy": "#c7b3b3",
                    "sb_available": "#d6ebff"
                },
                "timeline": `${selectedProvider && selectedService && selectedLocation && selectedCategory ? 'modern' : "modern"}`,
                "datepicker": `${selectedProvider && selectedService && selectedLocation && selectedCategory ? 'top_calendar' : "inline_datepicker"}`,
                "is_rtl": false,
                "app_config": {
                    "clear_session": 0,
                    "allow_switch_to_ada": 0,
                    "predefined": predefined
                },
                "button_title": "Book now",
                "button_background_color": "#5281bc",
                "button_text_color": "#ffffff",
                "button_position": "right",
                "button_position_offset": "55%",
                "container_id": "sbw_9p1dpb"
            }
        );
    }, []);
    return (
        <div id="sbw_9p1dpb" ref={widgetContainerRef} className='h-[80vh] w-full bg-black'></div>
    );
}
export default SimplySignInWidget;