import React from 'react'
import { FacebookIcon, InfoIcon, InstagramIcon, MailIcon, MapIcon, TikTokIcon } from '../components/icons'
import Map from '../components/GoogleMap'
import transition from '../transition'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { useIsMedium } from '../hooks/useMediaQuery'

const About = () => {

    const isMedium = useIsMedium()

    return (
        <section className="w-full h-auto lg:h-full flex flex-col items-center justify-between bg-eggshell ">
            <div className="group w-full h-full flex flex-col lg:flex-row items-center justify-between relative">
                <div className="w-full h-full rounded-r-sm mx-2 lg:mx-0 border-b-transparent lg:border-b-white border-b flex flex-col items-center justify-between ">
                    <div className="w-full bg-black flex flex-col items-center justify-center py-36 lg:py-48">
                        <h2 className="font-semibold text-xl text-left w-full px-2 text-eggshell lg:text-center lg:text-3xl lg:mb-3 ">
                            <span className="text-2xl font-extrabold text-phoenixOrange lg:text-6xl">
                                Phoenix{" "}
                            </span>
                            is Boston's Go-To Recording Studio
                        </h2>
                        <p className="font-light text-md lg:text-xl tracking-tight px-2 text-eggshell lg:px-32 lg:w-[50%] lg:text-center">
                            Since 2012, Phoenix Down Recording has provided Boston with
                            professional recording mixing and mastering services. Founder John
                            Scott, engineer for Lil Baby, BIA and many more, built Phoenix
                            with a goal of providing all Clients with a limitless recording
                            experience. Today, Phoenix is staffed with top local engineers,
                            and develops talent through internships and collaborative events.
                            The studio recently renovated to improve lighting, seating, and
                            provide Analog recording options.
                        </p>
                    </div>
                    <div className="w-full flex flex-col lg:flex-row items-start justify-center h-full pt-0 lg:h-64 lg:pt-12 bg-black/80 ">
                        <div className="h-auto w-full flex flex-col items-center justify-start border-t border-white lg:border-none my-4 lg:my-0">
                            <h2 className="w-full text-center text-3xl font-semibold text-eggshell mt-10">
                                Located At
                            </h2>
                            <p className="w-full text-eggshell text-center">
                                228 Lowell St, Somerville MA 02144
                            </p>
                        </div>

                        <div className="h-auto w-full flex flex-col items-center justify-start px-4 lg:px-12 my-4 lg:my-0">
                            <h2 className="w-full text-center text-3xl font-semibold text-eggshell mt-6">
                                Contact Us
                            </h2>
                            <div className="flex flex-row items-center justify-between w-full text-eggshell border-b border-eggshell">
                                {" "}
                                <h3>Phone:</h3>{" "}
                                <p className="text-sm lg:text-md">973 525 9445</p>
                            </div>
                            <div className="flex flex-row items-center justify-between w-full text-eggshell border-b border-eggshell">
                                {" "}
                                <h3>Email:</h3>{" "}
                                <p className="text-sm lg:text-md">
                                    phoenixdownrecording@gmail.com
                                </p>
                            </div>
                            {/* <div className='flex flex-row-reverse items-center justify-center w-full text-eggshell mt-6'>        <Link to="/pages/contact" className='border rounded-sm bg-white/5 px-4 py-2'> Send a Message </Link>
              </div> */}
                        </div>

                        <div className="h-auto w-full flex flex-col items-center justify-start my-4 lg:my-0">
                            <h2 className="w-full text-center text-3xl font-semibold text-eggshell mt-6">
                                Connect on
                            </h2>
                            <div className="w-full flex flex-row justify-center items-center gap-3 mt-2">
                                <a
                                    className="w-10 h-10"
                                    href="https://www.instagram.com/phxdownstudios/"
                                >
                                    <InstagramIcon className="w-full h-full fill-eggshell" />
                                </a>
                                <a className="w-10 h-10" href="https://www.tiktok.com/@phxdown">
                                    <TikTokIcon className="w-full h-full fill-eggshell" />
                                </a>
                                <a
                                    className="w-10 h-10"
                                    href="https://www.facebook.com/PhoenixDownRecording/"
                                >
                                    <FacebookIcon className="w-full h-full fill-eggshell" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {isMedium && (
                    <>
                        <motion.img
                            initial={{ x: 1000 }}
                            animate={{ x: 0 }}
                            transition={{ delay: 0.13, duration: 0.4, type: "spring" }}
                            className="w-[50%] h-[50%]  right-[-25%] top-[0%] object-cover hidden lg:block rounded-md absolute  rotate-0 group-hover:scale-110 z-[2] opacity-100 transition-all duration-300 ease-out shadow-md shadow-black hover:right-[-20%]"
                            src="/phoenix_a_room_station.jpg"
                        />

                        <motion.img
                            initial={{ x: -1000 }}
                            animate={{ x: 0 }}
                            transition={{ delay: 0.13, duration: 0.4, type: "spring" }}
                            className="w-[50%] h-[50%]  left-[-28%]  bottom-[20%] object-cover hidden lg:block rounded-tl-md absolute  scale-105 rotate-2 opacity-100 z-[3] transition-all duration-300 ease-out shadow-md shadow-black hover:left-[-20%]"
                            src="/phoenix_b_room_station.jpg"
                        />
                    </>
                )}
            </div>

            <Map />

        </section>
    );
}

export default transition(About)