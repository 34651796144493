import React from 'react'
import { useIsMedium } from '../hooks/useMediaQuery'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import transition from '../transition'
import { VirtualizedCarousel } from '../components/VirtualizedCarousel'

const Home = () => {
    const Items = [
        {
            image: "./ashes_cover.jpg",
            id: "0"
        },
        {
            image: "./blanco4_cover.jpg",
            id: "1"
        },
        {
            image: "./ragerover_cover.jpg",
            id: "2"
        },
        {
            image: "./smokesignal_cover.jpeg",
            id: "3"
        },
        {
            image: "./waverunner_cover.jpeg",
            id: "4"
        }
    ]

    const images = [
        "./ashes_cover.jpg",
        "./blanco4_cover.jpg",
        "./ragerover_cover.jpg",
        "./smokesignal_cover.jpeg",
        "./waverunner_cover.jpeg"
    ];

    const isMedium = useIsMedium()

    return (
        <>
            {isMedium ?
                <div className='w-full bg-eggshell flex flex-col gap-12 xl:gap-18'>
                    <div className='w-full h-auto relative'>
                        <VirtualizedCarousel
                            itemCount={images.length}
                            itemsPerView={5}>
                            {({ index }) => {
                                const modulo = index % images.length;
                                const imageIndex = modulo < 0 ? images.length + modulo : modulo;
                                return (
                                    <div style={{ height: "100%", padding: "0 0.3rem" }}>
                                        <img
                                            draggable={false}
                                            alt="Cover Art"
                                            style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                            src={images[imageIndex]}
                                        />
                                    </div>
                                );
                            }}
                        </VirtualizedCarousel>
                        <div className='absolute -bottom-4 mx-auto inset-x-0 w-full py-4 rounded-t-lg border-white/60  bg-gradient-to-t from-transparent via-black/60 to-transparent flex items-center justify-center z-[5] text-white'>
                            <p className='text-3xl font-light select-none'>Made at <span className='text-phoenixOrange font-bold text-6xl select-none'>Phoenix</span><span className='text-2xl ml-1 font-extrabold select-none'>.</span></p>
                        </div>
                    </div>
                    <motion.div
                        initial={false}
                        transition={{ staggerChildren: 0.2 }}
                        className='w-full h-auto flex flex-col lg:flex-row items-center justify-between gap-14 xl:gap-16 px-10 pt-4'>

                        <motion.div
                            initial={{ x: 100 }}
                            animate={{ x: 0 }}
                            transition={{ type: 'spring', ease: 'easeIn' }}

                            className='group h-[600px] w-full relative flex flex-col items-center justify-center rounded-md'>
                            {/* <div className='absolute top-20 left-8 md:left-4 md:top-4 w-[95%] h-auto text-left text-5xl font-light text-eggshell select-none'>
            <AnimatedText multiLines={true} lines={['Bostons Go-To','Recording','Studio']} className='!text-eggshell !text-6xl' lineClass='text-6xl'/>
            </div> */}
                            <img className='w-full h-full object-cover rounded-md' src="/phoenix_a_room_station.jpg" />
                            <img className='w-full h-full mx-auto inset-x-0 inset-y-0 object-cover rounded-md absolute opacity-0 group-hover:opacity-100 transition-all duration-300 ease-out' src="/phoenix_b_room_booth.jpg" />
                            <div className='w-[40%] h-14 absolute bottom-4 left-0'>
                                <Link to="/pages/rooms" className='border-2 select-none w-full h-full mx-4 border-white/60 flex items-center justify-center text-white bg-black/60 rounded-md'> <h2 className='text-center text-lg lg:text-2xl font-bold tracking-wide'>Explore Rooms</h2></Link>
                            </div>
                        </motion.div>


                        <motion.div
                            initial={{ x: 100 }}
                            animate={{ x: 0 }}
                            transition={{ type: 'spring', ease: 'easeIn' }}

                            className='group h-[800px] xl:h-[600px] w-full relative flex flex-col items-center justify-center rounded-md'>
                            {/* <div className='absolute top-20 left-8 md:left-4 md:top-4 w-[95%] h-auto text-left text-5xl font-light text-eggshell select-none'>
            <AnimatedText multiLines={true} lines={['Bostons Go-To','Recording','Studio']} className='!text-eggshell !text-6xl' lineClass='text-6xl'/>
            </div> */}
                            {/* <img className='w-full h-[600px] object-cover rounded-md' src="/ed.jpg" /> */}
                            <img className='w-[50%] h-[50%]  right-0 top-0 object-cover rounded-md absolute  rotate-3 group-hover:scale-110 z-[2] opacity-100 transition-all duration-300 ease-out shadow-md shadow-black' src="/julia.jpg" />
                            <img className='w-[50%] h-[50%]  left-0 top-0 object-cover rounded-md absolute  group-hover:-rotate-1 scale-105 opacity-100 transition-all duration-300 ease-out ' src="/kyle.jpeg" />
                            <img className='w-[50%] h-[50%]  right-0 bottom-2 object-cover  absolute group-hover:scale-105 group-hover:-rotate-4 z-[4] opacity-100 transition-all duration-300 ease-out shadow-md shadow-black' src="/scho.jpeg" />
                            <img className='w-[50%] h-[50%]  left-0 bottom-1 object-cover rounded-tl-md absolute  scale-105 -rotate-2 opacity-100 transition-all duration-300 ease-out shadow-md shadow-black' src="/ed.jpg" />


                            <div className='w-[40%] h-14 absolute bottom-4 left-0 z-[8]'>
                                <Link to="/pages/team" className='border select-none w-full h-full mx-4 border-white/60 flex items-center justify-center text-white bg-black/60 rounded-md'> <h2 className='text-center text-lg lg:text-2xl font-bold tracking-wide '>Meet The Team </h2></Link> </div>
                        </motion.div>
                        <motion.div
                            initial={{ x: 100 }}
                            animate={{ x: 0 }}
                            transition={{ type: 'spring', ease: 'easeIn' }}

                            className='group h-[600px] w-full relative flex flex-col items-center justify-center rounded-md'>
                            {/* <div className='absolute top-20 left-8 md:left-4 md:top-4 w-[95%] h-auto text-left text-5xl font-light text-eggshell select-none'>
            <AnimatedText multiLines={true} lines={['Bostons Go-To','Recording','Studio']} className='!text-eggshell !text-6xl' lineClass='text-6xl'/>
            </div> */}
                            <img className='w-full h-full object-cover rounded-md' src="/baby.png" />
                            <div className='w-[30%] h-14 absolute bottom-4 left-0'>
                                <Link to="/pages/rooms" className='border-2 select-none w-full h-full mx-4 border-white/60 flex items-center justify-center text-white bg-black/60 rounded-md'> <h2 className='text-center text-lg lg:text-2xl font-bold tracking-wide'>About Us</h2></Link>
                            </div>
                        </motion.div>
                    </motion.div>


                </div> :
                <div className='w-full h-auto relative bg-eggshell'>
                    <motion.div
                        initial={false}
                        transition={{ staggerChildren: 0.2 }}
                        className='w-full flex flex-col items-center justify-around gap-4'>

                        <motion.div
                            initial={{ y: -100, scale: 1.3 }}
                            animate={{ y: 0, scale: 1 }}
                            transition={{ type: 'spring', ease: 'easeIn', duration: 0.4, delay: 0.3 }}
                            className='h-full w-[104%] relative flex flex-col items-center justify-center'>
                            {/* <div className='absolute top-20 left-8 md:left-4 md:top-4 w-[95%] h-auto text-left text-5xl font-light text-eggshell select-none'>
            <AnimatedText multiLines={true} lines={['Bostons Go-To','Recording','Studio']} className='!text-eggshell !text-6xl' lineClass='text-6xl'/>
            </div> */}
                            <img className='w-[98%] h-full object-cover rounded-md' src="/phoenix_a_room_station.jpg" />
                            <div className='w-[44%] h-14 absolute bottom-4 right-0'>
                                <Link to="/pages/rooms" className='border rounded-tl-md rounded-bl-md border-white/60 select-none w-full h-full mx-4 flex items-center justify-start text-white bg-black/60 pl-4'> <h2 className='text-center text-lg lg:text-2xl font-semibold '>View Rooms </h2></Link> </div>
                        </motion.div>


                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ ease: 'easeIn', duration: 0.4, delay: 0.1 }}
                            className='w-full h-full flex flex-row items-center justify-center my-2 relative'>
                            <VirtualizedCarousel
                                itemCount={images.length}
                                itemsPerView={3}>
                                {({ index }) => {
                                    const modulo = index % images.length;
                                    const imageIndex = modulo < 0 ? images.length + modulo : modulo;
                                    return (
                                        <div style={{ height: "100%", padding: "0 0.3rem" }}>
                                            <img
                                                draggable={false}
                                                alt="Cover Art"
                                                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                                src={images[imageIndex]}
                                            />
                                        </div>
                                    );
                                }}
                            </VirtualizedCarousel>
                            <div className='absolute -bottom-12 mx-auto inset-x-0 w-full py-12 rounded-t-lg border-white/60  bg-gradient-to-t from-transparent via-black to-transparent flex items-center justify-center z-[5] text-white'> <p className='text-xl font-light'>Made at <span className='text-phoenixOrange font-bold text-2xl'>Phoenix</span><span className='text-xl ml-1 font-extrabold'>.</span></p> </div>
                        </motion.div>

                        <motion.div
                            initial={{ x: 100 }}
                            animate={{ x: 0 }}
                            transition={{ type: 'spring', ease: 'easeIn' }}
                            className='h-full w-full relative flex flex-col items-center justify-center shadow-t-md shadow-eggshell/50'>
                            {/* <div className='absolute top-20 left-8 md:left-4 md:top-4 w-[95%] h-auto text-left text-5xl font-light text-eggshell select-none'>
            <AnimatedText multiLines={true} lines={['Bostons Go-To','Recording','Studio']} className='!text-eggshell !text-6xl' lineClass='text-6xl'/>
            </div> */}
                            <img className='w-[98%] h-[98%] object-cover rounded-md ' src="/ed.jpg" />
                            <div className='w-[50%] h-14 absolute bottom-4 left-0'>
                                <Link to="/pages/team" className='border select-none w-full h-full mx-4 border-white/60 flex items-center justify-center text-white bg-black/60 rounded-md'> <h2 className='text-center text-lg lg:text-2xl font-semibold '>Meet The Team </h2></Link> </div>
                        </motion.div>

                        <motion.div
                            initial={{ x: 100 }}
                            animate={{ x: 0 }}
                            transition={{ type: 'spring', ease: 'easeIn' }}

                            className='h-80 w-full relative flex flex-col items-center justify-center shadow-t-md shadow-eggshell/50'>
                            {/* <div className='absolute top-20 left-8 md:left-4 md:top-4 w-[95%] h-auto text-left text-5xl font-light text-eggshell select-none'>
            <AnimatedText multiLines={true} lines={['Bostons Go-To','Recording','Studio']} className='!text-eggshell !text-6xl' lineClass='text-6xl'/>
            </div> */}
                            <img className='w-full h-full object-cover ' src="/baby.png" />
                            <div className='w-[50%] h-14 absolute bottom-4 left-0'>
                                <Link to="/pages/about" className='border select-none w-full h-full mx-4 border-white/60 flex items-center justify-center text-white bg-black/60 rounded-md'> <h2 className='text-center text-lg lg:text-2xl font-semibold '>About Us</h2></Link> </div>
                        </motion.div>

                    </motion.div>
                </div>}

        </>
    )
}

export default transition(Home)