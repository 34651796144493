import React, { useEffect, useRef, useState } from 'react';
import { SimplybookWidget } from './widget';
function SimplyBookingWidget({selectedProvider, selectedService, selectedLocation, selectedCategory}) {
    const widgetContainerRef = useRef(null);
    useEffect(() => {
        let predefined =  {
        }
        if (selectedProvider){
            predefined.provider = selectedProvider.id
        }
        if (selectedService){
            predefined.service = selectedService.id
        }
        if (selectedLocation){
            predefined.location = selectedLocation.id
        }
        if (selectedCategory){
            predefined.category = selectedCategory.id
        }

        console.log(predefined)

                                
            new SimplybookWidget(
                {
                    "widget_type": "iframe",
                    "url": "https:\/\/phxdown.simplybook.me",
                    "navigate": "booking",
                    "theme": "concise",
                    "theme_settings": {
                        "timeline_hide_unavailable": "1",
                        "hide_past_days": "0",
                        "timeline_show_end_time": "1",
                        "timeline_modern_display": "as_slots",
                        "light_font_color": "#ffffff",
                        "sb_secondary_base": "#0b0d0e",
                        "sb_base_color": "#F2B23F",
                        "display_item_mode": "list",
                        "booking_nav_bg_color": "#212020",
                        "sb_review_image": "",
                        "dark_font_color": "#0b0d0e",
                        "btn_color_1": "#F2B23F",
                        "sb_company_label_color": "#F2B23F",
                        "hide_img_mode": "0",
                        "show_sidebar": "1",
                        "sb_busy": "#c7b3b3",
                        "sb_available": "#d6ebff"
                    },
                    "timeline": `modern`,
                    "datepicker": `top_calendar`,
                    "is_rtl": false,
                    "app_config": {
                        "clear_session": 0,
                        "allow_switch_to_ada": 0,
                        "predefined":predefined
                    },
                    "button_title": "Book now",
                    "button_background_color": "#5281bc",
                    "button_text_color": "#ffffff",
                    "button_position": "right",
                    "button_position_offset": "55%",
                    "container_id": "sbw_9p1dpb"
                }
            );
    }, []);
    return (
       <div id="sbw_9p1dpb" ref={widgetContainerRef} className='h-full pt-16'></div>
    );
}
export default SimplyBookingWidget;
