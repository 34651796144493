import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from "framer-motion"
import wrap from '../utils/wrap';
import transition from '../transition';

const Locations = [
    {
        "Name": "A-Room",
        "Images": [
            {
                "Link": "/phoenix_a_room_station.jpg",
                "Description": ["27in Monitor", "Rhode Studio Mic", "Audio-Reactive Lighting"],
                "id": 0,
                "icons": [
                    {
                        name: "Auspurger Speakers",
                        position: "left-[47%] top-[34%]"
                    }
                ]
            },
            {
                "Link": "/phoenix_a_room_booth.jpg",
                "Description": ["Macbook M1 Pro", "Antares Autotune", "Analog Box"],
                "id": 1,
                "icons": [
                    {
                        name: "Slate ML-1 Condenser Microphone",
                        position: "left-[42%] top-[30%]"
                    }
                ]
            }]
    },
    {
        "Name": "B-Room",
        "Images": [
            {
                "Link": "/phoenix_b_room_station.jpg",
                "Description": ["Macbook M1 Pro", "Antares Autotune"],
                "id": 0,
                "icons": [
                    {
                        name: "Auspurger Speakers",
                        position: "left-[48%] top-[34%]"
                    }
                ]
            },
            {
                "Link": "/phoenix_b_room_booth.jpg",
                "Description": ["27in Monitor", "Rhode Studio Mic", "Dynamic Soundproofing"],
                "id": 1,
                "icons": [
                    {
                        name: "Slate ML-1 Condenser Microphone",
                        position: "right-[50%] top-[32%]"
                    }
                ]
            }]
    },
    {
        "Name": "C-Room",
        "Images": [
            {
                "Link": "/phoenix_c_room_station.jpg",
                "Description": ["27in Monitor", "Rhode Studio Mic", "Dynamic Soundproofing"],
                "id": 0,
                "icons": [
                    {
                        name: "Slate ML-1 Condenser Microphone",
                        position: "right-[32%] top-[60%]"
                    }
                ]
            }]
    }]

const sliderVariants = {
    incoming: (direction) => ({
        x: direction > 0 ? "100%" : "-100%",
        scale: 1.2,
        opacity: 0
    }),
    active: { x: 0, scale: 1, opacity: 1 },
    exit: (direction) => ({
        x: direction > 0 ? "-100%" : "100%",
        scale: 1,
        opacity: 0.2
    })
};

const sliderTransition = {
    duration: 1,
    ease: [0.56, 0.03, 0.12, 1.04]
};



const Rooms = () => {

    const [selectedRoom, setSelectedRoom] = useState(Locations[0]);
    const [[imageCount, direction], setImageCount] = useState([0, 0]);

    useEffect(() => {
        if (selectedRoom) {
            setImageCount([0, 0])
        }
    }, [selectedRoom]);


    const activeImageIndex = wrap(0, selectedRoom.Images.length, imageCount);


    const swipeToImage = (swipeDirection) => {
        setImageCount([imageCount + swipeDirection, swipeDirection]);
    };

    const dragEndHandler = (dragInfo) => {
        const draggedDistance = dragInfo.offset.x;
        const swipeThreshold = 50;
        if (draggedDistance > swipeThreshold) {
            swipeToImage(-1);
        } else if (draggedDistance < -swipeThreshold) {
            swipeToImage(1);
        }
    };

    const skipToImage = (imageId) => {
        let changeDirection;
        if (imageId > activeImageIndex) {
            changeDirection = 1;
        } else if (imageId < activeImageIndex) {
            changeDirection = -1;
        }
        setImageCount([imageId, changeDirection]);
    };



    return (
        <>
            <section className='w-full h-screen flex flex-col items-center justify-between lg:px-36 xl:px-64 lg:pb-8 bg-eggshell'>
                {/* <motion.div className='w-[105%] h-full fixed bg-cover hidden md:block'     
            key={imageCount}
            initial={{opacity: 0.4}}      
            animate={{opacity: 1}}      
          style={{
            backgroundImage: `url(${selectedRoom.Images[activeImageIndex].Link})`,
            backgroundRepeat: 'no-repeat'
          }} /> */}
                <div className='relative w-full h-full bg-black flex flex-col items-center overflow-hidden lg:rounded-md lg:shadow-md lg:shadow-black/40 md:mt-20'>
                    <AnimatePresence initial={false} custom={direction}>

                        <motion.div
                            key={imageCount}
                            style={{
                                backgroundImage: `url(${selectedRoom.Images[activeImageIndex].Link})`
                            }}
                            custom={direction}
                            variants={sliderVariants}
                            initial="incoming"
                            animate="active"
                            exit="exit"
                            transition={sliderTransition}
                            drag="x"
                            dragConstraints={{ left: 0, right: 0 }}
                            dragElastic={1}
                            onDragEnd={(_, dragInfo) => dragEndHandler(dragInfo)}
                            className="absolute h-full w-full bg-cover bg-no-repeat bg-center will-change-auto">
                            {selectedRoom.Images[activeImageIndex].icons && (
                                selectedRoom.Images[activeImageIndex].icons.map((icon) => {
                                    return (
                                        <motion.a
                                            initial={{ opacity: 0, scale: 1.6 }}
                                            animate={{ opacity: 1, scale: 1 }}
                                            exit={{ opacity: 0 }}
                                            transition={{ duration: 0.5, delay: 0.2 }}
                                            className={`group absolute z-[5] ${icon.position} bg-black/80 group-hover:bg-black border-2 border-white rounded-full w-8 h-8 flex items-center justify-center select-none  transition-all duration-300`}>
                                            <h3 className='font-bold text-white p-0 text-center'>i</h3>
                                            <p className='left-[108%] w-auto py-[2px] px-2 text-lg font-light  bg-black/80 whitespace-nowrap absolute text-white top-[-1] translate-y-4 group-hover:translate-y-0 opacity-0 group-hover:opacity-100 transition-all duration-300 ease-out'>  {icon.name}</p>
                                        </motion.a>
                                    )
                                })
                            )

                            }
                        </motion.div>
                    </AnimatePresence>

                    {/* <motion.div className='absolute hidden left-2 top-2 bg-white shadow-black shadow-inner w-[30%] px-2 py-2 h-auto border-2 border-white/20 rounded-md md:flex items-start justify-start'> 
                     <AnimatedText multiLines={true} lines={selectedRoom.Images[activeImageIndex].Description} className='!text-black !italic !text-2xl !text-center !items-start !justify-start'/>
                  </motion.div> */}
                    <div className='absolute bottom-0 w-full h-[12%] py-2 px-2 flex flex-row items-center justify-center gap-3  z-[2]'>
                        {Locations.map((location, index) => {
                            return (
                                <button onClick={() => setSelectedRoom(Locations[index])} className={` ${selectedRoom.Name == location.Name ? '' : ''}  bg-black/80 w-[45%] lg:w-[18%] h-auto py-3 md:py-4 px-4 md:px-6 flex items-center justify-center relative text-eggshell font-semibold lg:font-bold`}><h2>{location.Name}</h2> <span className={`h-[2px] bg-white ${selectedRoom.Name == location.Name ? 'w-full' : 'w-0'} transition-all duration-200 ease-in absolute bottom-0 mx-auto inset-x-0`} /></button>
                            )
                        })
                        }
                        <motion.div className='absolute w-full h-full -top-[98%] md:-top-[95%] z-[1] flex flex-row items-center justify-center gap-4'>
                            {selectedRoom.Images.map((image) => (
                                <div
                                    key={image.id}
                                    onClick={() => skipToImage(image.id)}
                                    className="relative h-24 w-30 cursor-pointer p-[2px] rounded-sm bg-eggshell/60"
                                >
                                    <img src={image.Link} alt="Musician" className='h-full w-full object-cover object-center   shadow-md' />
                                    <div
                                        className={`absolute top-0 left-0 h-full w-full select-none scale-x-0 transition-all duration-300 ease-in-out origin-center p-2 ${image.id === activeImageIndex ? "scale-x-100" : null
                                            }`}
                                    >
                                        <div className='thumbnailBackground w-full h-full z-[2] opacity-80'></div>
                                    </div>
                                </div>
                            ))}
                        </motion.div>
                    </div>
                </div>


            </section>
        </>
    )
}

export default transition(Rooms)