import React from 'react'
import { InstagramIcon, TikTokIcon, FacebookIcon } from './icons'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div className='w-full bg-black flex flex-col items-center justify-center pt-2 gap-1 border-t-2 border-t-eggshell z-[5]'>
            <div className='w-full flex flex-row items-center justify-between md:justify-center md:gap-12 px-1'><h3 className='text-sm font-semibold text-eggshell'>&copy; {new Date().getFullYear()}&nbsp;Phoenix Down</h3>
                <p className='text-xs text-eggshell font-light hidden lg:block'>228 Lowell St, Somerville, MA 02144 </p>
            </div>
            <div className='w-full md:w-60 flex flex-row items-center justify-between md:justify-center md:gap-12 px-1'>
                <div className='w-full flex flex-row items-center justify-start md:justify-center gap-2'>
                    <a className='w-5 h-5' href="https://www.instagram.com/phxdownstudios/"><InstagramIcon className="w-full h-full fill-eggshell" /></a>
                    <a className='w-5 h-5' href="https://www.tiktok.com/@phxdown"><TikTokIcon className="w-full h-full fill-eggshell" /></a>
                    <a className='w-5 h-5' href="https://www.facebook.com/PhoenixDownRecording/"><FacebookIcon className="w-full h-full fill-eggshell" /></a>
                </div>
                <div className='w-full flex flex-row items-center justify-end md:justify-center gap-2 text-sm text-eggshell'>
                    <Link className='hover:scale-[1.02] hover:font-bold transition-all duration-200 ease-out' to="/">Home</Link>
                    <Link className='hover:scale-[1.02] hover:font-bold transition-all duration-200 ease-out' to="/pages/team">Team</Link>
                    <Link className='hover:scale-[1.02] hover:font-bold transition-all duration-200 ease-out' to="/pages/rooms">Rooms</Link>
                    <Link className='hover:scale-[1.02] hover:font-bold transition-all duration-200 ease-out' to="/pages/about">About</Link>
                    {/* <Link to="/pages/about">Contact</Link> */}
                </div>
            </div>
            <div className='w-full flex flex-row items-center justify-center px-2 mt-1 pb-1'><p className='text-xs text-eggshell/70'>Created by <a className='font-bold hover:underline' href="https://venice.fm/">Venice</a> and <a className='font-bold hover:underline' href="https://www.vytroware.com/">Vytroware</a></p></div>
        </div>
    )
}

export default Footer