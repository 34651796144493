import React, { useState, useEffect, useRef } from 'react'
import { BurgerIcon, CrossIcon, DownArrow, FacebookIcon, InstagramIcon, PhoenixIcon, TikTokIcon, UserIcon } from './icons'
import { motion, AnimatePresence } from "framer-motion";
import { Link } from 'react-router-dom'
import { useIsMedium } from '../hooks/useMediaQuery';
import SimplySignInWidget from './SimplyLoginWidget';

export default function NavBar({ currentUser, setCurrentUser, bookingMenuOpen, setBookingMenuOpen, currentPage }) {
    const [mobileNavOpen, setMobileNavOpen] = useState(false);

    const ref = useRef()
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    useEffect(() => {
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
                setIsMenuOpen(false)
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)

        // Increase the logo size to overflow the navbar, ex: scale large and translate -y

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [isMenuOpen])


    const hideNavItemsVariant = {
        opened: {
            opacity: 0,
            y: "-100%",
            transition: {
                duration: 0.5,
                ease: "easeInOut"
            }
        },
        closed: {
            opacity: 1,
            y: "0%",
            transition: {
                delay: 1.1,
                duration: 0.5,
                ease: "easeInOut"
            }
        }
    };

    const mobileMenuVariant = {
        opened: {
            y: "0%",
            transition: {
                delay: 0.15,
                duration: 1.1,
                ease: [0.74, 0, 0.19, 1.02]
            }
        },
        closed: {
            y: "-100%",
            transition: {
                delay: 0.35,
                duration: 0.63,
                ease: [0.74, 0, 0.19, 1.02]
            }
        }
    };

    const fadeInVariant = {
        opened: {
            opacity: 1,
            transition: {
                delay: 1.2
            }
        },
        closed: { opacity: 0 }
    };

    const ulVariant = {
        opened: {
            transition: {
                delayChildren: 1,
                staggerChildren: 0.18
            }
        },
        closed: {
            transition: {
                staggerChildren: 0.06,
                staggerDirection: -1
            }
        }
    };

    const liVariant = {
        opened: {
            opacity: 1,
            y: "0%",
            transition: {
                duration: 0.65,
                ease: "easeOut"
            }
        },
        closed: {
            opacity: 0,
            y: "100%",
            transition: {
                duration: 0.25,
                ease: "easeInOut"
            }
        }
    };

    let MotionLink = motion(Link);

    const HeaderLink = ({ link, title }) => {
        return (
            <MotionLink to={link} className='group py-2 px-2 flex items-center justify-center relative select-none'>
                <h2 className='text-white font-semibold text-xl'>{title}</h2>
                <span className={` ${currentPage === `${link}` && 'w-full'} w-0 h-[2px] bg-white absolute bottom-2 from-center group-hover:w-full transition-200 transition-all ease-out`} />
            </MotionLink>
        )
    }

    const isMedium = useIsMedium()


    return (
        <>
            {isMedium ?
                <nav className={`px-2 sm:px-4 py-3 bg-black/80 transition-all duration-300 w-full fixed top-0 ${bookingMenuOpen ? 'opacity-0 -translate-y-20' : 'opacity-1 translate-y-0'}`}>
                    <div className='w-full flex flex-row items-center justify-center gap-16 relative'>
                        <div className='flex flex-row items-center justify-center lg:gap-12 xl:gap-20 w-full mr-16'>
                            <HeaderLink link="/" title="Home" />
                            <HeaderLink link="/pages/team" title="Team" />
                            <HeaderLink link="/pages/rooms" title="Rooms" />
                            <HeaderLink link="/pages/about" title="About" />
                            {/* <HeaderLink link="/pages/contact" title="Contact" /> */}
                            <motion.button
                                onClick={() => setBookingMenuOpen(true)}
                                className='group py-2 px-2 flex items-center justify-center relative select-none'>
                                <h2 className='text-white font-semibold text-xl'>Booking</h2>
                                <span className={`w-0 h-[2px] bg-white absolute bottom-2 from-center group-hover:w-full transition-200 transition-all ease-out`} />
                            </motion.button>
                        </div>


                    </div>
                    <motion.a href="/" className='w-16 h-16 absolute left-4 inset-y-0 mx-auto my-auto'>
                        <PhoenixIcon className='stroke-white fill-white' />
                    </motion.a>
                    <motion.button
                        onClick={() => setIsMenuOpen(true)}
                        className='flex flex-row items-center justify-center mr-1 absolute right-6 my-auto inset-y-0'>
                        <div className='flex items-center justify-center w-full'>
                            <div className='flex items-center justify-center h-12 w-12 rounded-sm select-none'>
                                {/* <img src={'/default.png'} className='rounded-full h-full w-full' /> */}
                                <UserIcon className="stroke-eggshell fill-eggshell h-10 w-10" />
                            </div>

                        </div>

                    </motion.button>
                    {isMenuOpen &&

                        <motion.div
                            initial={{ scaleY: 0 }}
                            animate={{ scaleY: 1 }}
                            exit={{ scaleY: 0 }}
                            ref={ref} className='absolute top-16 right-8 h-auto w-[50vw] overflow-hidden bg-black/95 backdrop-blur-sm backdrop-brightness-150 shadow-black/20 shadow-md z-[8] rounded-sm flex flex-col items-center justify-between border-black border origin-top'>

                            <SimplySignInWidget />
                        </motion.div>
                    }
                </nav>

                :

                <motion.nav className={`px-2 sm:px-4 py-2.5 bg-black/80 backdrop-blur-sm relative transition-all duration-300 ${bookingMenuOpen ? 'opacity-0 -translate-y-20' : 'opacity-1 translate-y-0'}`}
                    initial="closed"
                    animate={mobileNavOpen ? "opened" : "closed"}>
                    <div className='mx-auto flex flex-wrap items-center justify-between'>
                        <motion.a href="/" variants={hideNavItemsVariant} className='w-[10%] lg:w-[2%] h-full'>
                            <PhoenixIcon className='stroke-white fill-white' />
                        </motion.a>
                        <div className='flex flex-row items-center justify-between'>
                            <motion.button variants={hideNavItemsVariant}
                                onClick={() => setIsMenuOpen(true)}
                                className='flex flex-row items-center justify-center mr-1'>
                                <div className='flex items-center justify-center w-full'>
                                    <div className='flex items-center justify-center h-10 w-10 rounded-sm select-none'>
                                        {/* <img src={'/default.png'} className='rounded-full h-full w-full' /> */}
                                        <UserIcon className="stroke-eggshell fill-eggshell h-8 w-8" />
                                    </div>

                                </div>

                            </motion.button>

                            <motion.button
                                variants={hideNavItemsVariant}
                                onClick={() => setMobileNavOpen(true)}
                            >
                                <div className='flex items-center justify-center h-10 w-10'>
                                    <BurgerIcon className='stroke-white fill-white  h-10 w-10' />
                                </div>
                            </motion.button>
                            <AnimatePresence >
                                {isMenuOpen &&

                                    <motion.div
                                        initial={{ scaleY: 0 }}
                                        animate={{ scaleY: 1 }}
                                        exit={{ scaleY: 0 }}
                                        ref={ref} className='absolute top-16 right-8 h-auto w-80 overflow-hidden bg-black/95 backdrop-blur-sm backdrop-brightness-150 shadow-black/20 shadow-md z-[8] rounded-sm flex flex-col items-center justify-between border-black border origin-top'>

                                        <SimplySignInWidget />
                                    </motion.div>
                                }
                            </AnimatePresence>
                            <motion.div variants={mobileMenuVariant} className="fixed top-0 left-0 h-screen w-full flex flex-col items-center bg-black z-[15]">
                                <motion.div variants={mobileMenuVariant} className='px-2 sm:px-4 py-2.5 w-full flex flex-wrap items-center justify-between '>
                                    <motion.div variants={fadeInVariant} className='w-[10%] lg:w-[2%] h-full'>
                                        <PhoenixIcon className=' fill-eggshell' />
                                    </motion.div>
                                    <motion.button
                                        variants={fadeInVariant}
                                        onClick={() => setMobileNavOpen(false)}
                                        className='flex items-center justify-center h-10 w-10'
                                    >
                                        <CrossIcon className='stroke-eggshell h-10 w-10' />
                                    </motion.button>
                                </motion.div>
                                <motion.ul variants={ulVariant} className='mt-14 list-none text-eggshell font-semibold'>

                                    <motion.li whileTap={{ scale: 0.95 }} className='my-8 overflow-y-hidden select-none flex items-center justify-center'>
                                        <MotionLink to="/" variants={liVariant} onClick={() => setMobileNavOpen(false)} className='text-center uppercase text-4xl cursor-pointer '>Home</MotionLink>
                                    </motion.li>
                                    <motion.li whileTap={{ scale: 0.95 }} className='my-8 overflow-y-hidden select-none flex items-center justify-center'>
                                        <MotionLink to="/pages/team" variants={liVariant} onClick={() => setMobileNavOpen(false)} className='text-center uppercase text-4xl cursor-pointer'>Team</MotionLink>
                                    </motion.li>
                                    <motion.li whileTap={{ scale: 0.95 }} className='my-8 overflow-y-hidden select-none flex items-center justify-center'>
                                        <MotionLink to="/pages/rooms" variants={liVariant} onClick={() => setMobileNavOpen(false)} className='text-center uppercase text-4xl cursor-pointer'>Rooms</MotionLink>
                                    </motion.li>
                                    <motion.li whileTap={{ scale: 0.95 }} className='my-8 overflow-y-hidden select-none flex items-center justify-center'>
                                        <MotionLink to="/pages/about" variants={liVariant} onClick={() => setMobileNavOpen(false)} className='text-center uppercase text-4xl cursor-pointer'>About Us</MotionLink>
                                    </motion.li>

                                </motion.ul>
                                <motion.div variants={fadeInVariant} className="mt-6 w-full px-4 flex flex-col items-center justify-between">
                                    <h5 className='font-semibold text-eggshell/60'>Phoenix Down Recording</h5>
                                    <h5 className='font-light text-eggshell/60'>228 Lowell St, Somerville, MA</h5>
                                </motion.div>
                                <motion.div variants={fadeInVariant} className="mt-6 w-full px-20 flex flex-row items-center justify-center gap-6">
                                    <a className='h-8 w-8' href="https://www.instagram.com/phxdownstudios/"> <InstagramIcon className="fill-eggshell/80" /> </a>
                                    <a className='h-8 w-8' href="https://www.tiktok.com/@phxdown"> <TikTokIcon className="fill-eggshell/80" /> </a>
                                    <a className='h-8 w-8' href="https://www.facebook.com/PhoenixDownRecording/"> <FacebookIcon className="fill-eggshell/80" /> </a>
                                </motion.div>
                            </motion.div>
                        </div>
                    </div>
                </motion.nav>
            }
        </>
    )
}

