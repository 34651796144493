import React, { useState, useEffect } from 'react'

export const BookingInfoWidgetMenu = ({ selectedDate, selectedLocation, selectedProvider, selectedCategory, setSelectedProvider, setSelectedLocation, setSelectedService, setSelectedDate, setCurrentMenu, availableBookings, setAvailableBookings, selectedDuration, setSelectedDuration, providers, locations, services, categories, durations }) => {

    //Maintain state of Menu
    const [infoMode, setInfoMode] = useState('duration')

    const handleInfoClick = (e) => {
        if (e === 'duration') {
            if (infoMode === e) {
                setInfoMode('')
            } else {
                setInfoMode('duration')
            }
        }
        if (e == 'engineer') {
            if (infoMode === e) {
                setInfoMode('')
            } else {
                setInfoMode('engineer')
            }
        }
        if (e == 'room') {
            if (infoMode === e) {
                setInfoMode('')
            } else {
                setInfoMode('room')
            }
        }
    }

    const [invalidProvider, setInvalidProvider] = useState()

    useEffect(() => {
        setTimeout(() => setInvalidProvider(false), 800)
    }, [invalidProvider])

    const [invalidLocation, setInvalidLocation] = useState()

    useEffect(() => {
        setTimeout(() => setInvalidLocation(false), 800)
    }, [invalidLocation])

    const [invalidDuration, setInvalidDuration] = useState()

    useEffect(() => {
        setTimeout(() => setInvalidDuration(false), 800)
    }, [invalidDuration])

    const [possibleProviders, setPossibleProviders] = useState(providers.map((provider, index) => index))
    const [possibleLocations, setPossibleLocations] = useState(locations.map((location, index) => index))
    const [possibleDurations, setPossibleDurations] = useState(durations)

    useEffect(() => {
        if (possibleLocations.length === 1) {
            setSelectedLocation(locations[possibleLocations])
        } else {
        }
    }, [possibleLocations])

    useEffect(() => {
        if (possibleProviders.length === 1) {
            setSelectedProvider(providers[possibleProviders])
        } else {
        }
    }, [possibleProviders])

    useEffect(() => {
        if (possibleDurations.includes(selectedDuration)) {
        } else {
            setSelectedDuration()
        }
    }, [possibleDurations])




    const filterSelectedProvider = (e) => {
        return (e.filter(b => { return b.provider_id === selectedProvider.id }))
    }

    const filterSelectedLocation = (e) => {
        return (e.filter(b => { return b.location_id === selectedLocation.id }))
    }
    const clearFilters = () => {
        setSelectedLocation()
        setSelectedProvider()
        setSelectedDuration()
        setPossibleProviders(providers.map((provider, index) => index))
        setPossibleLocations(locations.map((location, index) => index))
        setPossibleDurations(durations)
    }

    const FilterOptions = (name, data) => {
        if (name === 'provider') {
            let possibleLocationIndexes = []
            let possibleDurationLengths = []
            locations.map((location, index) => {
                if (location.units.includes(data.id)) {
                    possibleLocationIndexes.push(index)
                }
            })
            services.map((service) => {
                if (service.providers.includes(Number(data.id))) {
                    possibleDurationLengths.push(service.duration)
                }
            })
            setPossibleLocations(possibleLocationIndexes)
            setPossibleDurations(possibleDurationLengths)
            if (selectedLocation) {

            }
            if (selectedDuration) {

            }
        }

        if (name === 'location') {
            let possibleProviderIndexes = []
            providers.map((provider, index) => {
                if (data.units.includes(provider.id)) {
                    possibleProviderIndexes.push(index)
                }
            })
            if (selectedDuration) {
                let validProviders = possibleProviders.filter((e) => possibleProviderIndexes.includes(e))
                setPossibleProviders(validProviders)
            }
        }

        if (name === 'duration') {
            let possibleServiceProviders = []
            let possibleProviderIndexes = []
            services.map((service) => {
                if (data === service.duration) {
                    possibleServiceProviders.push(service.providers[0])
                }
            })
            providers.map((provider, index) => {
                if (possibleServiceProviders.includes(Number(provider.id))) {
                    possibleProviderIndexes.push(index)
                }
            })
            setPossibleProviders(possibleProviderIndexes)
        }
    }

    const ResetOptions = (name) => {
        if (name === 'provider') {
            setPossibleDurations(durations)
            let maxProviders = providers.map((provider, index) => index)
            if (selectedLocation) {
                let possibleProviderIndexes = []
                providers.map((provider, index) => {
                    if (selectedLocation.units.includes(provider.id)) {
                        possibleProviderIndexes.push(index)
                    }
                })

                if (selectedDuration) {
                    let validProviders = maxProviders.filter((e) => possibleProviderIndexes.includes(e))
                    setPossibleProviders(validProviders)
                } else {
                    return setPossibleProviders(possibleProviderIndexes)
                }
            }
        }
    }

    const handleFormSubmission = () => {
        if (selectedDuration && selectedLocation && selectedProvider) {
            services.forEach((service) => {
                if ((service.providers[0] === Number(selectedProvider.id)) && (service.duration === selectedDuration)) {
                    setSelectedService(service)
                }
            })
            setCurrentMenu('Widget')
        } else {
            if (!selectedDuration) {
                //console.log('No Duration Selected')
                setInvalidDuration(true)
            } if (!selectedLocation) {
                //console.log('No Location Selected')
                setInvalidLocation(true)
            } if (!selectedProvider) {
                //console.log('No Provider Selected')
                setInvalidProvider(true)
            }
        }
    }

    function ProviderScroll() {
        const handleProviderSelection = (e) => {
            setSelectedProvider(e)
            FilterOptions('provider', e)
        }
        return (
            <div className='w-full h-auto flex'>
                <div className='grid grid-rows-2 grid-flow-col place-items-center gap-1 overflow-x-scroll w-auto'>
                    {providers.map((provider, index) => (
                        <button
                            disabled={!(possibleProviders.includes(index))}
                            key={provider.id}
                            onClick={() => handleProviderSelection(provider)}
                            className={`list-none w-28 h-28 lg:w-64 lg:h-32 overflow-hidden my-1 rounded-md shadow-black border-2 shadow-sm  relative cursor-pointer
                    ${selectedProvider && (selectedProvider.id === provider.id) ? 'border-phoenixOrange' : 'border-black'}
                    ${(possibleProviders.includes(index)) ? 'opacity-100' : 'opacity-50'}
                    `} >
                            {provider.picture_path ? <img className="h-28 lg:h-48 w-full object-cover" src={`https://phxdown.simplybook.me/${provider.picture_path}`} /> : <img className="h-28 lg:h-48 w-full object-cover" src='./default.png' />
                            }
                            <h3 className={`absolute bottom-0 mx-auto w-full text-center text-lg lg:text-2xl font-semibold text-eggshell bg-black/50 ${selectedProvider && selectedProvider.id == provider.id && 'border-phoenixOrange'} `}>{provider.name}</h3>
                        </button>
                    ))}
                </div>
            </div>
        )
    }
    function getProviderIndexes(source, data) {
        if (source === 'location') {
            let validProviderIndexes = []
            providers.forEach((provider, index) => {
                if (provider.locations.includes(data.id)) {
                    validProviderIndexes.push(index)
                }
            })
            return validProviderIndexes
        }
        if (source === 'duration') {
            let validProviders = []
            let validProviderIndexes = []
            services.forEach((service) => {
                if (service.duration === data) {
                    validProviders.push(service.providers[0])
                }
            });
            providers.forEach((provider, index) => {
                if (validProviders.includes(Number(provider.id))) {
                    validProviderIndexes.push(index)
                }
            });
            return validProviderIndexes
        }
    }
    function LocationDisplay() {
        const handleLocationSelection = (e) => {
            setSelectedLocation(e)
            if (selectedDuration) {
                setPossibleProviders((getProviderIndexes('location', e)).filter((e) => getProviderIndexes('duration', selectedDuration).includes(e)))
            } else {
                setPossibleProviders((getProviderIndexes('location', e)))
            }
        }

        return (
            <>
                <div className='w-full h-auto flex'>
                    <div className='w-full flex flex-row md:flex-row items-center justify-between gap-1'>
                        {locations.map((location, index) => (
                            <button
                                disabled={!(possibleLocations.includes(index))}
                                key={location.id}
                                onClick={() => handleLocationSelection(location)}
                                className={`list-none w-full border  relative cursor-pointer 
                    ${selectedLocation && selectedLocation.id == location.id ? 'border-phoenixOrange' : 'border-black'} 
                    ${(possibleLocations.includes(index)) ? 'opacity-100' : 'opacity-60'} `}
                            >

                                {location.picture_path ? <img className={`h-20 lg:h-36 w-full object-cover saturate-50 hover:saturate-100 ${selectedLocation && selectedLocation.id == location.id && 'saturate-100'}`} src={`https://phxdown.simplybook.me/${location.picture_path}`} /> : <div className="h-20 w-full object-cover bg-textSecondary" />
                                }

                                <span className={`duration-300 transition-all ease-in opacity-100 hover:opacity-100 absolute bottom-2 md:inset-y-0 right-1 w-[40%] h-6 md:h-12 flex items-center justify-center rounded-b-lg bg-black/40 ${selectedLocation && selectedLocation.id == location.id && 'opacity-100'}  `}><h3 className='text-eggshell font-bold text-md md:text-4xl'>{location.name} </h3></span>
                            </button>
                        ))}
                    </div>
                </div>
            </>
        )
    }

    function DurationDisplay() {

        const handleDurationSelection = (e) => {
            setSelectedDuration(e)
            if (selectedLocation) {
                setPossibleProviders((getProviderIndexes('duration', e)).filter((e) => getProviderIndexes('location', selectedLocation).includes(e)))
            } else {
                setPossibleProviders((getProviderIndexes('duration', e)))
            }
        }
        return (
            <>
                <div className='w-full h-auto flex'>
                    <ul className='w-full grid grid-flow-col grid-rows-2 gap-2 md:grid-rows-4'>
                        {durations.map((duration, index) => (
                            <li className={`py-1 md:px-4 md:py-4 w-full border rounded-sm transition-all duration-300 ease-in bg-eggshell/5 border-eggshell
                  ${(possibleDurations.includes(duration)) ? 'border-black' : 'border-none opacity-40'} 
                  ${selectedDuration && (duration === selectedDuration) && 'border-phoenixOrange'}`}
                            >
                                <button disabled={!(possibleDurations.includes(duration))} className='w-full flex items-center justify-center' onClick={() => handleDurationSelection(duration)}>
                                    <p className='font-semibold text-md text-white'>
                                        {(duration / 60)} Hours
                                    </p>
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </>
        )
    }

    return (
        <div className='w-full h-full flex flex-col items-center pt-16 lg:pt-20 pb-4 relative'>
            <div className='h-full flex flex-col gap-3 lg:gap-16 items-center justify-start w-[98%] lg:w-[90%]'>
                <div className={`h-auto w-full flex flex-col items-center justify-center mt-1`}>
                    <div className='w-full flex flex-row items-center justify-between border-b border-white text-xl font-semibold mb-2'>

                        <h2>Engineer</h2>

                        <h3 className={`${invalidDuration ? 'text-red-500' : 'text-phoenixOrange'} text-light`}>
                            {selectedProvider && `${selectedProvider.name}`}
                            {invalidProvider && 'Engineer is Required'}
                        </h3>

                    </div>
                    <ProviderScroll providers={providers} selectedProvider={selectedProvider} setSelectedProvider={setSelectedProvider} />
                </div>
                <div className={`h-24 w-full flex flex-col items-center justify-center`}>
                    <div className='w-full flex flex-row items-center justify-between border-b border-white text-xl font-semibold mb-2'>

                        <h2>Location</h2>

                        <h3 className={`${invalidLocation ? 'text-red-500' : 'text-phoenixOrange'} text-light`}>
                            {selectedLocation && `${selectedLocation.name}`}
                            {invalidLocation && 'Room is Required'}
                        </h3>

                    </div>
                    <LocationDisplay locations={locations} />
                </div>
                <div className={`h-auto w-full flex flex-col items-center justify-center mt-1 scrollbar-thin overflow-x-scroll`}>
                    <div className='w-full flex flex-row items-center justify-between border-b border-white text-xl font-semibold mb-2'>

                        <h2>Duration</h2>

                        <h3 className={`${invalidDuration ? 'text-red-500' : 'text-phoenixOrange'} text-light`}>
                            {selectedDuration && `${(selectedDuration / 60)} Hour Session`}
                            {invalidDuration && 'Duration is Required'}
                        </h3>

                    </div>
                    <DurationDisplay durations={durations} />
                </div>

            </div>

            <div className='w-full h-[10%]  border-white border-y-2  flex flex-row items-center justify-between mt-2 px-2 md:px-12 absolute bottom-1 bg-black/60 backdrop-blur-sm'>
                <h3
                    onClick={() => clearFilters()}
                    className='border-b border-white text-white lg:text-xl cursor-pointer hover:opacity-70 transition-all duration-300 ease-out'>Clear Choices</h3>
                <button onClick={handleFormSubmission} className=' py-2 px-2 rounded-md md:px-6 md:py-4 bg-white text-black font-bold tracking-tight shadow-md shadow-black md:w-64'>
                    View Available Sessions
                </button>
            </div>
        </div>
    )
}


