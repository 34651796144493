//company methods
export const getUnitList = {
    urlObject: {url: "https://api.phoenixdownrecording.com/phnxdownapi/bookingapi/getUnitList", params: {api: "cmp"}},
    method: "getUnitList",
    params: ["true", "true"]
};
export const getEventList = {
    urlObject: {url: "https://api.phoenixdownrecording.com/phnxdownapi/bookingapi/getEventList", params: {api: "cmp"}},
    method: "getEventList",
    params: ["true", "true"]
};
export const getLocationsList = {
    urlObject: {url:"https://api.phoenixdownrecording.com/phnxdownapi/bookingapi/getLocationsList", params: {api: "cmp"}},
    method: "getLocationsList",
    params: ["true", "true"]
};

export const getCategoriesList = {
    urlObject: {url:"https://api.phoenixdownrecording.com/phnxdownapi/bookingapi/getCategoriesList", params: {api: "cmp"}},
    method: "getCategoriesList",
    params: ["true"]
};

export const getAvailableTimeIntervals = (date) => {
    return {
        urlObject: {url: "https://api.phoenixdownrecording.com/phnxdownapi/bookingapi/getAvailableTimeIntervals", params: {api: "cmp"}},
        method: "getAvailableTimeIntervals",
        params: [`${date}`, `${date}`]
    }
}

//user admin methods

export const getBookings = (dateFrom, dateTo = null) => {
    return {
        urlObject: {url: "https://api.phoenixdownrecording.com/phnxdownapi/bookingapi/getBookings", params: {api: "user"}},
        method: "getBookings",
        params: [{"date_from":dateFrom,"date_to":dateTo}]
    };
}
