import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'

export const BookNowIcon = ({setMenuOpen, isMenuOpen, currentPage}) => {
  return (
    <AnimatePresence > 
    {!isMenuOpen && 
        <motion.div 
        initial={{x: 100, 
                  transition: {ease: 'easeIn', duration: 0.5}
                }}
        animate={{x:0}}
        exit={{x:100, 
        transition: {ease: 'easeOut'}}}
        transition={{type: 'spring' }}
        className={`
        ${currentPage === '/' && 'bottom-24 right-5'} 
        ${currentPage === '/pages/rooms' && 'top-24 right-5'} 
        ${currentPage === '/pages/team' && 'top-24 right-5'} 
        ${currentPage === '/pages/contact' && 'top-16 left-5'} 
        ${currentPage === '/pages/about' && 'bottom-24 left-5'} 
        fixed z-10 flex flex-col items-center justify-center
        w-[90px] md:w-[120px] h-[90px] md:h-[120px]  overflow-hidden  cursor-pointer font-bold rotate-6 bg-black rounded-full shadow-md border border-eggshell/80 border-b-eggshell border-r-eggshell shadow-black
        hover:rotate-0 hover:border-phoenixOrange hover:border-2 hover:scale-110 hover:shadow-lg transition-transform duration-300 ease-out `} 
        onClick={() => setMenuOpen(true)}>
          <h2 className='select-none text-eggshell text-lg md:text-2xl'>BOOK</h2>
          <h2 className='select-none text-eggshell text-lg md:text-2xl'>NOW</h2>
        </motion.div>
    }
    </AnimatePresence>
  )
}
