import React, { useState } from "react";
import {Outlet} from 'react-router-dom'
import Footer from "./components/footer";
import NavBar from "./components/NavBar";
import { BookingMenuWidget } from "./components/BookingMenu_widget";

const NewLayout = ({locations, providers, services, categories, existingBookings, setCurrentUser, currentUser, currentPage}) => {

const [bookingMenuOpen, setBookingMenuOpen] = useState(false)

    return (
        <>
            <BookingMenuWidget currentPage={currentPage} locations={locations} providers={providers} services={services} categories={categories} existingBookings={existingBookings} currentUser={currentUser} setCurrentUser={setCurrentUser} bookingMenuOpen={bookingMenuOpen} setBookingMenuOpen={setBookingMenuOpen} />
            <div className="fixed w-full top-0 z-[25] font-dmSans"> 
            <NavBar {...{currentUser, setCurrentUser, bookingMenuOpen, setBookingMenuOpen, currentPage}} />   
            </div>
            <div className="font-dmSans transition-all duration-300 md:h-screen w-full overflow-x-hidden scrollbar-none md:scrollbar scrollbar-track-white scrollbar-thumb-eggshell hover:scrollbar-thumb-phoenixOrange scrollbar-corner-black scrollbar-thumb-rounded-sm flex flex-col bg-eggshell items-center justify-between">     
            <Outlet />
            <Footer />
            </div>
        </>
    );
}

export default NewLayout;