import * as React from "react"
export const PhoenixIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 384 384"
    {...props}
  >
    <path d="M385 111.625c-21.483 4.456-42.317 10.78-62.692 18.65-12.955 5.003-25.199 11.343-36.353 19.695-14.616 10.942-22.894 26.114-28.808 42.898-6.636 18.83-17.92 33.998-34.81 44.99-10.382 6.755-21.635 10.193-33.892 10.417-10.984.201-21.988-.22-32.856 1.942-6.556 1.305-12.608 4.022-19.09 6.36 2.53-6.06 6.947-10.432 12.228-14.003 8.272-5.592 17.788-8.206 27.173-11.12 3.975-1.234 7.984-2.4 11.866-3.888 10.676-4.094 15.832-11.406 15.862-22.914.026-9.673.177-19.301 2.058-28.84 2.613-13.25 8.356-24.866 17.722-34.641a25.761 25.761 0 0 1 2.583-2.349c.354-.28.904-.313 2.217-.729-3.293 11.918-7.168 23.271-5.719 35.763 3.557-9.555 7.24-18.791 12.085-27.484 12.814-22.992 31.29-38.584 57.545-44.115 10.569-2.227 21.207-1.598 31.849-1.34 1.435.034 3.02.147 4.471 2.143-20.4 2.068-38.16 9.642-53.374 22.893-5.363 4.671-10.379 9.689-13.745 16.29 3.625-2.767 7.22-5.574 10.88-8.293 21.508-15.974 45.519-25.03 72.3-27.517 13.484-1.253 26.544.14 40.09 2.493.41.824.41 1.574.41 2.699z" />
    <path d="M118.213 152.784c8.486 7.44 17.584 13.14 28.362 16.078-6.01-4.447-12.178-8.7-17.998-13.383-10.52-8.465-19.633-18.13-23.605-31.494-1.65-5.551-2.947-11.24-1.998-17.433 3.005.488 4.112 2.96 5.522 4.74 17.948 22.651 40.322 39.535 66.441 51.699 7.693 3.583 15.138 7.7 22.683 11.6 2.56 1.322 4.066 3.186 3.744 6.366-1.007 9.92-1.526 19.9-2.872 29.771-1.243 9.119-11.959 16.881-21.026 15.01-16.658-3.44-29.381-12.834-38.302-27.328-.13-.21.062-.619.202-1.688 10.498 4.105 20.653 9.042 33.539 9.896-28.319-13.486-54.376-27.387-63.472-60.296 4.177 1.425 6.13 4.126 8.78 6.462zM123.645 250.662c-13.725 10.775-24.63 23.88-36.296 36.212-13.65 14.429-28.264 27.453-47.992 32.888-11.3 3.114-22.842 3.615-34.396 2.17-.25-1.82.744-2.06 1.775-2.17 15.263-1.611 27.487-9.471 38.903-18.956 16.718-13.89 29.912-31.303 45.874-45.949 4.65-4.267 9.38-8.449 14.059-13.229-23.262 14.53-47.283 13.027-71.997 4.42 1.082-1.833 2.357-1.399 3.373-1.205 14.855 2.83 28.467-.856 41.217-8.2 16.993-9.787 35.121-15.026 54.781-14.633 12.17.244 24.213 1.782 36.202 3.786.608.101 1.346.26 1.399 1.896-17.037 4.162-32.974 11.184-46.902 22.97zM267.018 182.15c.669-5.652 6.147-8.164 7.222-13.103 13.97-.7 30.33 25.342 32.166 38.266-1.521.92-2.521-.311-3.527-.998-8.053-5.505-16.78-6.648-26.211-4.616-5.772 1.243-11.587 2.609-18.572 2.427 3.488-7.503 6.415-14.457 8.922-21.976z" />
  </svg>
)

export const BurgerIcon = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="inherit"
        strokeLinecap="round"
        strokeWidth={1.3}
        d="M4 18h16M4 12h16M4 6h16"
      />
    </svg>
  )

  export const CrossIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="w-full h-full"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="inherit"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.3}
      d="M19 5 5 19M5 5l14 14"
    />
  </svg>
)

  


export const ScreenIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={800}
    height={800}
    style={{
      enableBackground: "new 0 0 122.88 86.79",
    }}
    viewBox="0 -18.04 122.88 122.88"
    {...props}
  >
    <path d="M2.08 0h120.8v73.36H77.57v8.29h7.28v5.15H37.88v-5.15h7.28v-8.29H0V0h2.08zm116.65 4.15H4.15V69.2h114.57l.01-65.05z" 
    stroke="inherit"/>
  </svg>
)

export const HourglassIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
 
    viewBox="0 0 24 24"
    {...props}
  >
    <path 
    fill="#F2F2F2"
    d="M20 3a1 1 0 0 0 0-2H4a1 1 0 0 0 0 2h1.049c.146 1.836.743 5.75 3.194 8-2.585 2.511-3.111 7.734-3.216 10H4a1 1 0 0 0 0 2h16a1 1 0 0 0 0-2h-1.027c-.105-2.264-.631-7.487-3.216-10 2.451-2.252 3.048-6.166 3.194-8Zm-6.42 7.126a1 1 0 0 0 .035 1.767c2.437 1.228 3.2 6.311 3.355 9.107H7.03c.151-2.8.918-7.879 3.355-9.107a1 1 0 0 0 .035-1.767C7.881 8.717 7.227 4.844 7.058 3h9.884c-.169 1.844-.823 5.717-3.362 7.126ZM12 13s3 2.4 3 3.6V20H9v-3.4c0-1.2 3-3.6 3-3.6Z" />
  </svg>
)

export const ClockIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
 
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="inherit"
      fill="#1F1F1F"
      fillRule="evenodd"
      d="M12 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm9.828-5.243a1 1 0 0 1 1 1v4.968l3.527 1.34a1 1 0 1 1-.71 1.87l-4.172-1.586a1 1 0 0 1-.645-.935V7.757a1 1 0 0 1 1-1Z"
      clipRule="evenodd"
      
    />
  </svg>
)

export const FilterIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#F2F2F2"
      fillRule="evenodd"
      d="M15 10.5A3.502 3.502 0 0 0 18.355 8H21a1 1 0 1 0 0-2h-2.645a3.502 3.502 0 0 0-6.71 0H3a1 1 0 0 0 0 2h8.645A3.502 3.502 0 0 0 15 10.5zM3 16a1 1 0 1 0 0 2h2.145a3.502 3.502 0 0 0 6.71 0H21a1 1 0 1 0 0-2h-9.145a3.502 3.502 0 0 0-6.71 0H3z"
      clipRule="evenodd"
    />
  </svg>
)

export const DownArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m7 10 5 5 5-5"
    />
  </svg>
)

export const FacebookIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="-143 145 512 512"
    {...props}
  >
    <path d="M329 145h-432c-22.1 0-40 17.9-40 40v432c0 22.1 17.9 40 40 40h432c22.1 0 40-17.9 40-40V185c0-22.1-17.9-40-40-40zM169.5 357.6l-2.9 38.3h-39.3v133H77.7v-133H51.2v-38.3h26.5v-25.7c0-11.3.3-28.8 8.5-39.7 8.7-11.5 20.6-19.3 41.1-19.3 33.4 0 47.4 4.8 47.4 4.8l-6.6 39.2s-11-3.2-21.3-3.2-19.5 3.7-19.5 14v29.9h42.2z" />
  </svg>
)

export const TikTokIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    {...props}
  >
    <title>{"tiktok"}</title>
    <path d="M16.656 1.029c1.637-.025 3.262-.012 4.886-.025a7.762 7.762 0 0 0 2.189 5.213l-.002-.002A8.77 8.77 0 0 0 29 8.45l.028.002v5.036a13.327 13.327 0 0 1-5.331-1.247l.082.034a15.385 15.385 0 0 1-2.077-1.196l.052.034c-.012 3.649.012 7.298-.025 10.934a9.513 9.513 0 0 1-1.707 4.954l.02-.031c-1.652 2.366-4.328 3.919-7.371 4.011h-.014a9.071 9.071 0 0 1-5.139-1.31l.04.023C5.05 28.185 3.32 25.603 3 22.6l-.004-.041a23.163 23.163 0 0 1-.012-1.862c.49-4.779 4.494-8.476 9.361-8.476.547 0 1.083.047 1.604.136l-.056-.008c.025 1.849-.05 3.699-.05 5.548a4.29 4.29 0 0 0-5.465 2.619l-.009.03c-.133.427-.21.918-.21 1.426 0 .206.013.41.037.61l-.002-.024a4.26 4.26 0 0 0 4.382 3.586h-.009a4.198 4.198 0 0 0 3.451-1.994l.01-.018c.267-.372.45-.822.511-1.311l.001-.014c.125-2.237.075-4.461.087-6.698.012-5.036-.012-10.06.025-15.083z" />
  </svg>
)

export const InstagramIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="inherit"
      fillRule="evenodd"
      d="M12 18a6 6 0 1 0 0-12 6 6 0 0 0 0 12Zm0-2a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
      clipRule="evenodd"
    />
    <path fill="inherit" d="M18 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z" />
    <path
      fill="inherit"
      fillRule="evenodd"
      d="M1.654 4.276C1 5.56 1 7.24 1 10.6v2.8c0 3.36 0 5.04.654 6.324a6 6 0 0 0 2.622 2.622C5.56 23 7.24 23 10.6 23h2.8c3.36 0 5.04 0 6.324-.654a6 6 0 0 0 2.622-2.622C23 18.44 23 16.76 23 13.4v-2.8c0-3.36 0-5.04-.654-6.324a6 6 0 0 0-2.622-2.622C18.44 1 16.76 1 13.4 1h-2.8c-3.36 0-5.04 0-6.324.654a6 6 0 0 0-2.622 2.622ZM13.4 3h-2.8c-1.713 0-2.878.002-3.778.075-.877.072-1.325.202-1.638.361a4 4 0 0 0-1.748 1.748c-.16.313-.29.761-.36 1.638C3.001 7.722 3 8.887 3 10.6v2.8c0 1.713.002 2.878.075 3.778.072.877.202 1.325.361 1.638a4 4 0 0 0 1.748 1.748c.313.16.761.29 1.638.36.9.074 2.065.076 3.778.076h2.8c1.713 0 2.878-.002 3.778-.075.877-.072 1.325-.202 1.638-.361a4 4 0 0 0 1.748-1.748c.16-.313.29-.761.36-1.638.074-.9.076-2.065.076-3.778v-2.8c0-1.713-.002-2.878-.075-3.778-.072-.877-.202-1.325-.361-1.638a4 4 0 0 0-1.748-1.748c-.313-.16-.761-.29-1.638-.36C16.278 3.001 15.113 3 13.4 3Z"
      clipRule="evenodd"
    />
  </svg>
)

export const YoutubeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={800}
    height={800}
    viewBox="0 -3 20 20"
    {...props}
  >
    <title>{"youtube [#168]"}</title>
    <path
      fill="inherit"
      fillRule="evenodd"
      d="M7.988 9.586V3.974c1.993.938 3.536 1.843 5.36 2.82-1.505.834-3.367 1.77-5.36 2.792m11.103-8.403c-.344-.453-.93-.805-1.553-.922-1.833-.348-13.267-.349-15.099 0-.5.094-.945.32-1.328.673C-.5 2.429.005 10.452.393 11.75c.164.562.375.968.64 1.235.343.352.812.594 1.351.703 1.51.312 9.284.486 15.122.047a2.62 2.62 0 0 0 1.39-.712c1.49-1.49 1.388-9.962.195-11.841"
    />
  </svg>
)

export const TwitterIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={800}
    height={800}
    viewBox="0 -2 20 20"
    {...props}
  >
    <title>{"twitter [#154]"}</title>
    <path
      fill="inherit"
      fillRule="evenodd"
      d="M6.29 16c7.547 0 11.675-6.156 11.675-11.495 0-.175 0-.349-.012-.522A8.265 8.265 0 0 0 20 1.89a8.273 8.273 0 0 1-2.356.637A4.07 4.07 0 0 0 19.448.293a8.303 8.303 0 0 1-2.606.98 4.153 4.153 0 0 0-5.806-.175 4.006 4.006 0 0 0-1.187 3.86A11.717 11.717 0 0 1 1.392.738 4.005 4.005 0 0 0 2.663 6.13 4.122 4.122 0 0 1 .8 5.625v.051C.801 7.6 2.178 9.255 4.092 9.636a4.144 4.144 0 0 1-1.852.069c.537 1.646 2.078 2.773 3.833 2.806A8.315 8.315 0 0 1 0 14.185a11.754 11.754 0 0 0 6.29 1.812"
    />
  </svg>
)

export const LinktreeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={800}
    height={800}
    viewBox="0 0 24 24"
    {...props}
  >
    <path 
    fill="inherit"
    d="M7.953 15.066c-.08.163-.08.324-.08.486.08.517.528.897 1.052.89h1.294v4.776c0 .486-.404.89-.89.89H6.577a.898.898 0 0 1-.889-.891v-4.774H.992c-.728 0-1.214-.729-.89-1.377l6.96-12.627a1.065 1.065 0 0 1 1.863 0l2.913 5.585-3.885 7.042zm15.945 0-6.96-12.627a1.065 1.065 0 0 0-1.862 0l-2.995 5.586 3.885 7.04c.081.164.081.326.081.487-.08.517-.529.897-1.052.89h-1.296v4.776c.005.49.4.887.89.89h2.914a.9.9 0 0 0 .892-.89v-4.775h4.612c.73 0 1.214-.729.89-1.377z" />
  </svg>
)

export const MailIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m21 8-3.56 1.978c-1.986 1.103-2.979 1.655-4.03 1.87-.93.192-1.89.192-2.82 0-1.051-.215-2.044-.767-4.03-1.87L3 8m3.2 11h11.6c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874C21 17.48 21 16.92 21 15.8V8.2c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C19.48 5 18.92 5 17.8 5H6.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C3 6.52 3 7.08 3 8.2v7.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C4.52 19 5.08 19 6.2 19Z"
    />
  </svg>
)

export const MapIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 6h.01M9 20l-6-3V4l2 1m4 15 6-3m-6 3v-6m6 3 6 3V7l-2-1m-4 11v-3m0-7.8c0 1.767-1.5 3.2-3 4.8-1.5-1.6-3-3.033-3-4.8S10.343 3 12 3s3 1.433 3 3.2Z"
    />
  </svg>
)

export const InfoIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#0F0F0F"
      d="M10 10a1 1 0 0 0 1 1v6a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2V9h-2a1 1 0 0 0-1 1ZM12 8a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
    />
    <path
      fill="#0F0F0F"
      fillRule="evenodd"
      d="M23 4a3 3 0 0 0-3-3H4a3 3 0 0 0-3 3v16a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3V4Zm-2 0a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V4Z"
      clipRule="evenodd"
    />
  </svg>
)

export const LeftArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="m33.934 54.458 30.822 27.938a1.996 1.996 0 0 0 2.826-.138 1.999 1.999 0 0 0-.139-2.824l-29.642-26.87L64.67 22.921a1.998 1.998 0 1 0-2.963-2.685L33.768 51.059a1.994 1.994 0 0 0-.475 1.723 1.988 1.988 0 0 0 .641 1.676z" />
  </svg>
)

export const UserIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="_x32_"
    width={800}
    height={800}
    viewBox="0 0 512 512"
    {...props}
  >
    <path
      d="M256 265.308c73.252 0 132.644-59.391 132.644-132.654C388.644 59.412 329.252 0 256 0c-73.262 0-132.643 59.412-132.643 132.654 0 73.263 59.381 132.654 132.643 132.654zM425.874 393.104c-5.922-35.474-36-84.509-57.552-107.465-5.829-6.212-15.948-3.628-19.504-1.427-27.04 16.672-58.782 26.399-92.819 26.399-34.036 0-65.778-9.727-92.818-26.399-3.555-2.201-13.675-4.785-19.505 1.427-21.55 22.956-51.628 71.991-57.551 107.465C71.573 480.444 164.877 512 256 512s184.427-31.556 169.874-118.896z"
fill="inherit"    />
  </svg>
)