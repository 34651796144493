import React from 'react'
import Rooms from './pages/Rooms';
import Home from './pages/Home';
import Team from './pages/Team';
import { Routes, Route, useLocation } from "react-router-dom";
import NewLayout from './Layout';
import About from './pages/About';
import { AxiosInterceptor } from './interceptors/axios';
import { useState } from 'react';
import { AnimatePresence } from 'framer-motion';

const RouteStructure = ({ locations, categories, services, providers }) => {

    const [currentUser, setCurrentUser] = useState(null)

    const location = useLocation()


    return (
        <>
            <AnimatePresence mode="wait" >
                <Routes location={location} key={location.pathname}>
                    <Route path="/" element={<AxiosInterceptor>
                        <NewLayout currentPage={location.pathname} locations={locations} providers={providers} services={services} categories={categories} currentUser={currentUser} setCurrentUser={setCurrentUser} />
                    </AxiosInterceptor>}>
                        <Route exact path="/" index element={<Home />} />
                        <Route path="/pages/rooms" element={<Rooms />} />
                        <Route path="/pages/team" element={<Team />} />
                        <Route path="/pages/about" element={<About />} />
                    </Route>
                </Routes>
            </AnimatePresence>
        </>
    )
}

export default RouteStructure